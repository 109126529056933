/**=====================
    Slider css start
==========================**/


.snow-slider {
    position: relative;

    .slick-slide {
        position: relative;
    }
}

.home-slider,
.center-slider {

    .slick-prev,
    .slick-next {
        opacity: 0;
        transform: scale(1);
        transition: all 0.5s ease;
        margin-top: -10px;

        &:before {
            font: normal normal normal 14px/1 $font-awesome;
            opacity: 1;
            color: black;
            background-color: rgba($white, 0.5);
            border-radius: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }

    .slick-next {
        right: 1px;

        &:before {
            content: "\f105";
            font-family: $font-awesome;
            font-size: 10px;
        }
    }

    .slick-prev {
        left: 1px;
        z-index: 1;

        &:before {
            content: "\f104";
            font-family: $font-awesome;
            font-size: 10px;
        }
    }

    .home {
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        // height: 75vh; //changed
        height: 30vh !important; //added
        
        img {
            width: 100%; /* Ensure the image takes up the full width of its container */
            height: 100%; /* Automatically adjust the height to maintain the aspect ratio */
            max-width: 100% !important; /* Limit the maximum width of the image */
            max-height: 30vh !important; /* Limit the maximum height of the image */
            display: block !important; /* Ensure the image behaves as a block element */
            margin: 0 auto; /* Center the image horizontally */
        }
    }

    .slider-details {
        position: absolute;
        background-color: rgba($black, 0.1);
        border-radius: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        top: 26%;
        right: 18%;
        text-align: center;
        padding: 55px;
        width: 510px;
        height: 510px;

        &.blue-slide {
            background-color: #223b7d;
        }

        .btn-white {
            margin-top: 15px;
        }

        h1 {
            color: #ffffff;
            font-weight: 700;
        }

        h3 {
            color: $white;
            position: absolute;
            text-transform: uppercase;
            display: inline-block;

            &:after,
            &:before {
                content: "";
                position: absolute;
                width: 12px;
                height: 12px;
                background-color: white;
                border-radius: 100%;
                top: 8px;
            }

            &:after {
                left: -22px;
                top: 8px;
            }

            &:before {
                top: 8px;
                right: -22px;
            }
        }

        h2 {
            font-weight: 400;
            margin-bottom: 0;
            font-size: 45px;
        }

        h4 {
            font-size: 24px;
        }

        h2,
        h3,
        h4 {
            color: white;
        }
    }

    .slider-contain {
        // width: 100%;
        // height: 75vh; //changed
        height: 0vh;
        display: flex;
        align-items: flex-end;
        display: flex;
        position: relative !important;
        top: -20px;
        transform: translate(-50%, -50%);
        justify-content: center;

        div {
            a {
                // margin-bottom: 24px; //stock
                margin-bottom: -10px; //added
            }
        }

        h1 {
            margin-bottom: 0;
            margin-top: 10px;
            color: $dark-font;
        }

        h4 {
            font-weight: 700;
            letter-spacing: 0.4em;
            color: $grey;
            margin-bottom: 0;
        }

        .btn-solid,
        .btn-outline {
            margin-top: 20px;
        }

        &.product-detail {
            p {
                max-width: 50%;
                line-height: 30px;
                font-size: 16px;
                color: #777777;
            }

            h3 {
                margin-top: 14px;
                margin-bottom: 0;
                font-weight: 600;
                color: #777777;

                del {
                    margin-left: 10px;
                }
            }
        }
    }

    .thumbnail-porduct {
        position: absolute;
        bottom: 40px;
        left: 0;
        width: 100vw;

        img {
            margin: 0 auto;
            border: 1px solid transparent;
            opacity: 0.5;
        }

        .slick-current {
            img {
                border: 1px solid #dddddd;
                opacity: 1;
            }
        }
    }

    .p-left {
        .slider-contain {
            justify-content: flex-start;
        }
    }

    .p-right {
        .slider-contain {
            justify-content: flex-end;
        }
    }

    .p-center {
        .slider-contain {
            justify-content: center;
        }
    }

    .p-bottom {
        .slider-contain {
            align-items: flex-end;
            padding-bottom: calc(20px + (50 - 20) * ((100vw - 320px) / (1920 - 320)));
            ;

            >div {
                padding: 25px 30px;
                background-color: $white;
            }
        }
    }

    .text-center {
        .slider-contain {
            p {
                margin: 0 auto;
            }
        }
    }

    .home-decor {
        .decor {
            position: absolute;

            img {
                box-shadow: 0px 38px 62px -40px rgba(0, 0, 0, 0.2);
            }

            span {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 80px;
                font-size: 16px;
                line-height: 1.2;
                background-color: black;
                border-radius: 100%;
                color: white;
                font-weight: 600;
                padding: 10px;
                height: 80px;
                text-align: center;
                box-shadow: 0px 38px 62px -40px rgba(0, 0, 0, 0.2);
            }

            &.decor-1 {
                top: 26%;
                right: 13%;

                span {
                    animation: movebounce 3.2s linear infinite;
                }

            }

            &.decor-2 {
                top: 35%;
                right: 37%;

                span {
                    animation: movebounce 2s linear infinite;
                }
            }

            &.decor-3 {
                top: 52%;
                right: 12%;
                display: flex;
                align-items: center;

                img {
                    width: 40%;
                    transform: scaleX(-1);
                }

                span {
                    font-size: 17px;
                    background-color: transparent;
                    color: #222222;
                }
            }

            &.decor-4 {
                top: 32%;
                right: 35%;
                display: flex;
                align-items: center;

                img {
                    width: 40%;
                }

                span {
                    font-size: 17px;
                    background-color: transparent;
                    color: #222222;
                }
            }
        }
    }

    &:hover {

        .slick-prev,
        .slick-next {
            opacity: 1;
            transform: scale(2.5);
            transition: all 0.5s ease;

            &:before {
                opacity: 1;
            }
        }

        .slick-next {
            right: 100px;
        }

        .slick-prev {
            left: 100px;
        }
    }
}

.home-slider-container {
    .slider-contain {
        padding: 0 100px;
    }

    &.home-fashion {
        .slider-contain {
            padding: 0 calc(20px + (100 - 20) * ((100vw - 320px) / (1920 - 320)));
        }
    }
}

.center-slider {
    border-left: 1px solid $border-grey;
    border-right: 1px solid $border-grey;
}

.home-slider {
    &.text-white {
        .slider-contain {

            h1,
            h4 {
                color: $white;
            }
        }
    }
}

.gym-slider {
    .home-slider {
        .home {
            height: 85vh;

            .slider-contain {
                height: 85vh;

                h4,
                h1 {
                    color: $white;
                }
            }
        }
    }
}

.metro-slider {
    .home-slider {
        .home {
            background-position-y: 70%;

            .slider-contain {
                margin-top: 75px;

                h4,
                h1 {
                    color: $black;
                }
            }
        }
    }
}

.small-slider {
    .home-slider {
        .home {
            height: 55vh;

            .slider-contain {
                height: 55vh;

                h4,
                h1 {
                    color: $black;
                }
            }
        }
    }
}

.height-100 {
    .home-slider {
        .home {
            height: 100vh;
        }

        .slider-contain {
            height: calc(99vh + 80px);
        }
    }
}

.height-85 {
    .home-slider {
        .home {
            height: 85vh;
        }

        .slider-contain {
            height: 85vh;
        }
    }

    &.content_top {
        .slider-contain {
            padding-top: 150px;
            align-items: flex-start;

            h1 {
                font-size: 40px;
                letter-spacing: 0.05em;
            }

            .btn-solid {
                padding: 9px 18px;
                margin-top: 10px;
            }

            h4 {
                letter-spacing: 0.08em;
            }
        }
    }
}

.layout-7.home-slider {
    .home {
        height: 60vh;

        .slider-contain {
            height: 60vh;
        }
    }
}

.height-65 {
    .home {
        height: 65vh !important;

        .slider-contain {
            height: 65vh !important;
        }
    }
}

.height-80 {
    .home {
        height: 80vh;

        .slider-contain {
            height: 80vh;
        }
    }
}

.banner-slider {
    .height-banner {
        height: 100%;
    }

    .home {
        height: 81vh;

        .slider-contain {
            height: 81vh;
        }
    }

    .home-banner {
        >div {
            img {
                width: 100%;
            }

            &:last-child {
                img {
                    margin-top: 30px;
                }
            }
        }
    }
}

.absolute-banner {
    margin-top: -105px;

    .absolute-bg {
        background-color: white;
        position: relative;
        padding: 25px;
        box-shadow: 0 0 8px 0 #ddd;
    }

    &.mt-banner {
        margin-top: -55px;
    }
}

.home-fashion {
    margin-top: 25px;

    .home-slider {
        .slider-contain {
            h3 {
                margin-bottom: 0;
                margin-top: 10px;
                font-size: calc(16px + (24 - 16) * ((100vw - 320px) / (1920 - 320)));
            }
        }
    }
}

.slide-center {
    .slick-list {
        margin-left: -20px;
        margin-right: -20px;

        .slick-slide {
            >div {
                padding-left: 20px;
                padding-right: 20px;
            }
        }
    }
}

.home-form {
    margin-top: -40px;
    z-index: 1;
    position: relative;

    .table-form {
        box-shadow: 0px -1px 30px 0 rgba(0, 0, 0, 0.15);
        margin-top: 0;
        border-radius: 10px;
        padding: 20px 10px;
        background-color: white;

        .form-control {
            border: none;
            background-color: $grey-lighter;
        }

        .form-group {
            margin-bottom: 0;
        }

        .btn {
            font-size: 14px;
            width: 100%;
            border-radius: 5px;
            padding: 9px;
            line-height: 1;
            margin-top: 0;
        }
    }
}

.px-padding {
    padding: 0 calc(0px + (50 - 0) * ((100vw - 320px) / (1920 - 320)));
}
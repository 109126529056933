/*=====================
    29.Responsive CSS start
==========================*/

body {
    &.christmas {
        @media (min-width: 1630px) {
            .container {
                max-width: 1600px;
            }
        }

        @media (max-width: 1630px) {
            .home-slider {
                .slider-details {
                    width: 400px;
                    height: 400px;
                }
            }
        }
    }
}

@media (min-width: 1430px) {
    .container {
        max-width: 1400px;
    }
}

@media (min-width: 1630px) {
    .container-lg {
        max-width: 1610px;
    }
}

@media (max-width: 1630px) {
    .gradient-category {
        .gradient-border {
            height: 180px;
        }
    }
}

@media (max-width: 1430px) {
    header {
        &.left-header {
            .top-header {
                padding-left: 0;

                .header-contact {
                    display: none;
                }
            }

            .sidenav {
                left: -300px;

                &.open-side {
                    left: 0;
                }

                .sidebar-back {
                    display: block;
                    color: black;
                    border-bottom: 1px dashed $round-border;
                }

                .brand-logo {
                    display: none;
                }

                .left-sidebar_center {
                    padding: 0 0 25px 25px;
                }
            }

            .main-menu {
                .menu-left {
                    .navbar {
                        display: block;
                    }

                    .mobile-logo {
                        display: block;
                        border: none;
                    }
                }
            }

            .search-overlay {
                padding-left: 0;
            }
        }
    }

    .header-options {
        span {
            font-size: 14px;
        }
    }

    .blog-section {
        .review-box {
            .review-content {
                p {
                    margin-bottom: 35px;
                }
            }
        }
    }

    .left-sidebar_space {
        padding-left: 0;
    }

    .service-w-bg {
        .service-block {
            .media {
                padding: 13px;
                height: 100%;
            }

            h4 {
                font-size: 16px;
                margin-bottom: 4px;
            }

            svg {
                width: 50px;
                height: 50px;
            }
        }
    }

    .banner-slider .height-banner {
        height: unset;
    }

    .box-product {
        .theme-card {
            .offer-slider {
                .media {
                    .media-body {
                        .rating {
                            i {
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
        }

        .full-box {
            .theme-card {
                .offer-slider {
                    .product-box2 {
                        .media {
                            .media-body {
                                h4 {
                                    font-size: 16px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .pet-parallax {
        .pet-decor {
            left: 60px;
            bottom: -125px;

            img {
                width: 240px;
            }
        }
    }

    .tools_slider {
        .home-slider {
            .slider-contain {
                margin-left: 80px;
            }

            .home {

                .tools-parts,
                .tools-parts1 {
                    img {
                        width: 250px;
                    }
                }

                .tools-parts {
                    right: 0;
                }

                .tools-parts1 {
                    right: 10%;
                }

                #tools-move2 {
                    right: 7%;
                }
            }
        }
    }

    .bakery-card {
        &.theme-card {
            .offer-slider {
                img {
                    height: 120px;
                }
            }
        }

        .offer-slider {
            .media {
                .lable {
                    padding: 8px 4px;
                    left: 90px;
                    top: 6px;
                }
            }
        }
    }

    .cycle-box-row {
        >div {
            flex: 0 0 33.33%;
            max-width: 33.33%;
        }
    }

    .banner-style-1 {
        .custom-height {
            height: 374px;
        }

        .full-box {
            .theme-card {
                .offer-slider {
                    .product-box2 {
                        .media {
                            img.h-cls {
                                height: 210px;
                            }
                        }
                    }
                }
            }
        }
    }

    .banner-style-2 {
        &.absolute-banner {
            .absolute-bg {
                .collection-banner {
                    .absolute-img {
                        img {
                            width: 38%;
                        }
                    }
                }
            }
        }
    }

    .header-style-5 {
        &.color-style {
            .form_search {
                width: 500px;
            }
        }
    }

    .header-style-7 {
        .form_search {
            width: 390px;
        }
        .main-menu {
            .menu-left {
                .navbar {
                    padding: 30px 25px 30px 0;
                }
            }
        }
    }

    .home-slider,
    .center-slider {
        .home-decor {
            .decor.decor-3 {
                right: 4%;
            }
        }
    }

    .banner-offer {
        .collection-banner {
            .contain-banner {
                h2 {
                    font-size: 20px;
                }
            }
        }
    }

    // inner pages //
    .collection-product-wrapper {
        .product-top-filter {
            .product-filter-content {
                .search-count {
                    width: 100%;
                    border-left: 1px solid $round-border;
                    border-bottom: 1px solid $round-border;
                    text-align: center;
                }

                .collection-view {
                    width: 20%;
                    padding-right: 0 !important;
                    border-left: 1px solid $round-border;
                }

                .collection-grid-view {
                    padding: 20px !important;
                    padding-left: 0 !important;
                    width: 20%;

                    ul {
                        li {
                            &:last-child {
                                display: none;
                            }
                        }
                    }
                }

                .product-page-filter {
                    border-right: 1px solid $round-border;
                    width: 30%;
                }

                .product-page-per-view {
                    width: 30%;
                }
            }

            .popup-filter {
                .collection-view {
                    width: 10%;
                }

                .product-page-per-view {
                    width: 25%;
                }
            }
        }
    }

    .cart-section {
        .cart-buttons {
            >div {
                &:last-child {
                    padding-right: 38px;
                }
            }
        }

        tbody {
            tr {
                td {
                    min-width: 175px;

                    .qty-box {
                        .input-group {
                            .form-control {
                                width: 75px;
                            }
                        }
                    }
                }
            }
        }

        tfoot {
            tr {
                td {
                    padding-right: 41px;
                }
            }
        }
    }

    .product-right {
        .product-icon {
            .product-social {
                li {
                    padding-right: 10px;
                }
            }
        }
    }

    .product-form-box {
        .timer {
            padding-left: 17px;

            span {
                width: 55px;
            }
        }
    }
}

@media (max-width: 1430px) and (min-width: 1368px) {
    .banner-timer {
        .timer {
            margin-top: 45px;
        }
    }
}

@media (max-width: 1430px) and (min-width: 1200px) {
    .layout3-menu {
        .main-menu {
            .menu-left {
                .navbar {
                    padding: 40px 10px 40px 0;
                }
            }
        }

        .pixelstrap {
            >a {
                padding-right: 38px;
            }

            a {
                font-size: 14px;
            }
        }
    }

    .portfolio-section {
        &.metro-section {
            .product-box {
                .cart-wrap {
                    a {
                        i {
                            padding: 5px;
                        }
                    }

                    i {
                        font-size: 14px;
                    }

                    button {
                        i {
                            padding: 5px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 1400px) {
    .gradient-category {
        .gradient-border {
            height: 140px;
        }
    }
}

@media (max-width: 1367px) {
    h2 {
        font-size: 32px;
    }

    .title1 {
        .title-inner1 {
            padding-bottom: 10px;

            &:after {
                height: 2px;
            }
        }
    }

    .title3 {
        .line {
            height: 3px;

            &:after,
            &:before {
                height: 3px;
            }
        }

        .title-inner3 {
            margin-bottom: 10px;
        }
    }

    .title4 {
        .title-inner4 {
            padding-bottom: 15px;
        }
    }

    .title1 {
        &.title5 {
            hr[role="tournament6"] {
                margin: 10px auto 30px auto;
            }
        }
    }

    hr {
        &.style1 {
            height: 1px;
            margin-top: 7px;
            margin-bottom: 7px;
        }
    }

    .home-slider {
        .slider-details {
            right: 14%;
        }

        &:hover {

            .slick-prev,
            .slick-next {
                transform: scale(1.8);
            }
        }
    }

    .shop-sidebar-demo {
        .shop-main {
            .small-slider {
                .home {
                    > div {
                        background: linear-gradient(272deg, rgba(255, 255, 255, 0.40) 0%, rgba(255, 255, 255, 0.85) 100%);   
                    }
                }
            } 
        }
    }

    .product-box,
    .product-wrap {

        .product-detail,
        .product-info {
            .rating {
                i {
                    padding-right: 0;
                }
            }
        }
    }

    .blog-details {
        h4 {
            margin-top: 20px;
        }

        p {
            font-size: 16px;
        }
    }

    .service-block {
        h4 {
            font-size: 16px;
            margin-bottom: 5px;
        }
    }

    .service-w-bg {
        .service-block {
            h4 {
                font-size: 15px;
            }

            svg {
                width: 40px;
                height: 40px;
            }
        }
    }

    .banner-timer {
        background-position: right;

        .banner-text {
            h2 {
                font-size: 28px;
            }
        }
    }

    .collection-product-wrapper {
        .product-wrapper-grid {
            .product-five {
                flex: 0 0 33.33%;
                max-width: 33.33%;
                position: relative;
                width: 100%;
                min-height: 1px;
                padding-right: 15px;
                padding-left: 15px;
            }
        }
    }

    .subscribe-form {
        .form-control {
            width: 215px;
        }
    }

    .category-bg {
        .image-block {
            &.even {
                &:after {
                    bottom: 91%;
                }
            }
        }

        .contain-block {
            h2 {
                font-size: 30px;
            }

            &.even {
                &:after {
                    top: 91%;
                }
            }

            .category-btn {
                margin-bottom: 20px;
                margin-top: 20px;
            }

            &:after {
                bottom: 91%;
            }
        }
    }

    .full-banner {
        .banner-contain {
            h2 {
                font-size: 90px;
            }

            h3 {
                font-size: 55px;
            }

            h4 {
                font-size: 24px;
                padding-top: 8px;
            }

            .color {
                padding-top: 0;
            }
        }
    }

    .collection-banner {
        .contain-banner {
            &.banner-4 {
                padding-left: 45px;
                padding-right: 45px;

                h2 {
                    font-size: 28px;
                }
            }

            h2 {
                font-size: 55px;
                letter-spacing: 0.01em;
            }

            h4 {
                letter-spacing: 0.01em;
            }
        }

        &.christmas-banner {
            .contain-banner {
                >div {
                    background-color: rgba($black, 0.2);
                    padding: 25px 40px;
                }
            }
        }
    }

    .footer-theme2 {
        .contact-details {
            li {
                padding: 0 40px;
            }
        }

        .footer-mobile-title {
            display: none !important;
        }
    }

    .footer-theme {
        .sub-title {
            h4 {
                font-size: 16px;
            }

            .contact-list {
                li {
                    line-height: 20px;
                }

                i {
                    top: 17px;
                }
            }
        }
    }

    .theme-card {
        .offer-slider {
            .media {
                .media-body {
                    h4 {
                        margin-top: 10px;
                    }

                    a {
                        h6 {
                            margin-right: 50px;
                        }
                    }
                }
            }
        }
    }

    .full-box {
        .theme-card {
            .offer-slider {
                .product-box2 {
                    .media {
                        .media-body {
                            a {
                                h6 {
                                    margin-right: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .theme-tab {
        .tab-content {
            .product-tab {
                .tab-box {
                    .product-box2 {
                        img {
                            height: 160px;
                        }

                        .media {
                            .media-body {
                                .color-variant {
                                    li {
                                        margin-top: 15px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .blog-page {
        .blog-media {
            .blog-right {
                h4 {
                    line-height: 1.2;
                    margin-bottom: 10px;
                }

                h6 {
                    margin-bottom: 5px;
                }

                ul {
                    margin-bottom: 10px;
                }

                p {
                    line-height: 1.4;
                }
            }
        }
    }

    .pro_sticky_info {
        padding: 10px;
    }

    .is_stuck {
        margin-top: 30px;
    }

    .main-feature {
        .key-feature {
            margin-top: -50px;

            >div {
                margin-top: 50px;
            }
        }
    }

    .deal-category {
        .deal-content {
            h2 {
                font-size: 25px;
            }
        }
    }
}

@media (max-width: 1199px) {
    .hover-unset {
        position: fixed !important;
    }

    .space_sm {
        padding-left: 0;
    }

    .p-0-xl {
        padding: 0;
    }

    .center-slider-bicycle {
        &.product-m {
            .slick-slide {
                >div {
                    margin: 0;
                }
            }
        }

        .slick-prev,
        .slick-next {
            display: none;
        }
    }

    header {
        &.header-gym {
            .pixelstrap {
                >li {
                    >a {
                        color: black !important;
                        padding: 10px 15px;

                        &:hover,
                        &:focus,
                        &:active {
                            padding: 10px 15px;
                        }
                    }
                }
            }
        }

        &.left-header {
            &.left-header-relative {
                height: 100%;
                z-index: 9;
                height: 0;

                .pixelstrap {
                    ul {
                        ul {
                            background: transparent;
                        }
                    }

                    a {
                        .sub-arrow {
                            display: block;
                        }
                    }

                    >li {
                        >a {
                            text-align: left;
                            padding: 10px 25px;

                            &:hover,
                            &:focus,
                            &.highlighted {
                                padding: 10px 25px;
                            }
                        }
                    }
                }

                .sidenav {
                    position: fixed;
                    left: -300px;
                    width: 300px;
                    top: 0;
                    z-index: 10;
                    height: 100vh;
                    box-shadow: 0 0 1px 0 rgba(0, 0, 0, 0.25);

                    nav {
                        height: 100%;
                    }

                    .sidebar-overlay {
                        z-index: -1;
                    }

                    &.open-side {
                        left: 0;
                    }

                    .left-sidebar_center {
                        padding: 0 0 25px 25px;
                    }
                }
            }

            &.left-header-sm {
                .sidenav {
                    left: -300px;
                    top: 0;
                    width: 300px;

                    .sidebar-back {
                        margin-bottom: 0;
                    }

                    .left-sidebar_center {
                        .pixelstrap {
                            a {
                                .sub-arrow {
                                    display: block;
                                }
                            }

                            >li {
                                >a {
                                    display: flex;
                                    align-items: center;
                                    text-align: left;
                                    padding: 10px 25px;
                                    font-size: 14px;

                                    img {
                                        margin-left: 0;
                                        margin-right: 12px !important;
                                        width: 38px;
                                    }
                                }
                            }
                        }
                    }
                }

                &.open-side {
                    .sidenav {
                        left: 0;
                    }
                }
            }
        }

        &.header-tools {
            .pixelstrap {
                &.sm-horizontal {
                    li {
                        .lable-nav {
                            top: -8px;
                        }
                    }
                }
            }
        }

        &.header-5 {
            &.overlay-style {
                .pixelstrap {
                    >li {

                        >a,
                        >a:hover,
                        >a:active {
                            color: black;
                        }
                    }
                }
            }
        }
    }

    .header-style-1 {
        .toggle-nav {
            position: absolute;
            top: -55px;
            padding: 0;
            left: 0;
            z-index: 2;
        }

        .bg-light-xl {
            background-color: #f8f9fa !important;
        }

        &.sticky {
            .toggle-nav {
                top: -49px;
            }
        }
    }

    .header-style-2 {
        .main-nav-center {
            .toggle-nav {
                right: unset;
                left: 0;

                i {
                    color: white;
                    opacity: 1;
                }
            }
        }

        .main-menu {
            .brand-logo {
                padding-left: 25px;
            }
        }

        .pixelstrap {
            >li {
                >a {
                    color: #222222;

                    &:focus,
                    &.active {
                        color: #222222;
                    }
                }
            }
        }
    }

    .header-style-5 {
        .bottom-part {
            height: 0;

            .category-menu {
                .toggle-sidebar {
                    padding-bottom: 18px;
                }
            }

            .pixelstrap {
                >li {
                    >a {
                        color: #222;

                        &:hover,
                        &:focus,
                        &:active {
                            color: #222;
                        }
                    }
                }
            }

            &.bottom-light {
                padding-top: 0;
                border-top: none;

                .marketplace-sidebar {
                    &.sidenav {
                        &.fixed-sidebar {
                            position: fixed;
                            top: 0;
                            height: 100vh;
                            width: 300px;
                            display: block;
                            z-index: 9;
                            left: -300px;

                            .sm-vertical {
                                border: none;
                            }

                            &.open-side {
                                left: 0;
                            }
                        }
                    }
                }
            }
        }

        &.style-light {
            .bottom-part {
                &.bottom-light {
                    .main-nav-center {
                        .sm {
                            >li {
                                >a {
                                    padding-top: 12px;
                                    padding-bottom: 12px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.border-style {
            .bottom-part {
                &.bottom-light {
                    border-bottom: none;
                }
            }

            .main-menu {
                .menu-left {
                    .navbar {
                        padding: 30px 45px 30px 0;
                    }
                }
            }

            .main-nav-center {
                .toggle-nav {
                    right: 160px;
                    top: -98px;
                }
            }
        }

        &.color-style {
            &.style-classic {
                .bottom-part {
                    margin-bottom: 0;
                    z-index: unset;

                    .main-nav-center {
                        .sm {
                            >li {
                                >a {
                                    padding-top: 10px;
                                    padding-bottom: 10px;
                                }
                            }
                        }
                    }

                    .container {
                        background-color: transparent;
                        padding-left: 15px;
                        padding-right: 15px;
                    }
                }
            }
        }
    }

    .header-style-7 {
        .main-menu {
            .menu-left {
                .navbar {
                    padding: 30px 25px 30px 0;
                }
            }
            .menu-right {
                .icon-nav {
                    .delivery-area {
                        >div {
                            padding: 8px 18px;
                        }
                    }
                }
            }
        }
    }

    .header-compact {
        .menu-row {
            .delivery-area {
                position: absolute;
                top: -58px;
                left: 200px;
            }
        }
        .main-menu {
            .brand-logo {
                padding-top: 25px;
                padding-bottom: 25px;
            }
        }
        .bottom-part {
            padding-bottom: 0;
        }
        .main-menu {
            z-index: unset;
        }
        .main-nav-center {
            .toggle-nav {
                top: -91px;
            }
        }
    }

    .category-shop-section {
        .category-mobile-button {
            padding: 10px 30px;
            background-color: var(--theme-color);
            color: white;
            border-radius: 5px;
            font-size: 16px;
            margin-bottom: 30px;
            i {
                margin-right: 8px;
            }
        }
        .sidebar-overlay {
            visibility: hidden;
            opacity: 0;
            background-color: #212331;
            position: fixed;
            z-index: 10;
            top: 0;
            left: 0;
            width: 100%;
            height: 100vh;
            transition: all 0.5s ease;
            &.show {
                opacity: 0.8;
                visibility: visible;
                transition: all 0.5s ease;
            }
        }
        .nav {
            position: fixed;
            left: -320px;
            top: 0;
            width: 280px;
            z-index: 10;
            overflow: auto;
            background-color: $white;
            transition: all 0.5s ease;
            display: block;
            &.show {
                left: 0;
                transition: all 0.5s ease;
            }
            .sidebar-back {
                border-bottom: 1px solid $round-border;
                margin-bottom: 20px;
                padding-bottom: 20px;
                padding-top: 20px;
            }
        }
    }

    .banner-offer {
        .collection-banner {
            .contain-banner {
                &.banner-3 {
                    h2 {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .shop-sidebar-demo {
        .shop-sidebar {
            .pixelstrap.sm-vertical {
                a {
                    padding: 12px 23px;
                    img {
                        height: 30px;
                        width: 30px;
                    }
                }
            }
        }
    } 

    .left-sidebar-header {
        .main-menu {
            .menu-left {
                .navbar {
                    padding: 20px 25px 20px 0;
                }
            }
        }
    }

    .pt-res-0 {
        padding-top: 0 !important;
    }

    .svg-icon-menu {
        .pixelstrap {
            >li {
                >a {
                    img {
                        width: 30px;
                    }
                }
            }
        }
    }

    .service-w-bg {
        .service-block {
            .media {
                padding: 20px;
            }

            svg {
                width: 50px;
                height: 50px;
            }
        }
    }

    .service-style-border {
        .service-block {
            svg {
                width: 66px;
                margin-right: 4px;
            }

            .media-body {
                padding-left: 10px;
            }
        }
    }

    .marketplace-sidebar {
        &.sidenav {
            height: 100vh;
            width: 300px;
            position: fixed;
            z-index: 9;
            top: 0;
            left: -300px;

            .sm-vertical {
                background-color: transparent;
                padding: 0;
            }

            &.open-side {
                left: 0;
            }
        }

        &.sidenav {
            &.fixed-sidebar {
                display: block;
                height: 100vh;
                width: 300px;
                position: fixed;
                z-index: 9;
                top: 0;
                left: -300px;

                .sm-vertical {
                    background-color: transparent;
                    padding: 0;
                    border: none;
                }

                &.open-side {
                    left: 0;
                }
            }
        }
    }

    section,
    .section-t-space {
        padding-top: 60px;
    }

    .section-b-space {
        padding-bottom: 60px;
    }

    .portfolio-padding {
        padding-bottom: 30px;
    }

    .partition1 {
        margin-bottom: -25px;

        >div {
            margin-bottom: 25px;
        }
    }

    .title1 {
        h4 {
            padding-bottom: 8px;
        }

        .title-inner1 {
            padding-bottom: 12px;
            margin-bottom: 25px;
        }

        &.title5 {
            hr[role="tournament6"] {
                margin: 12px auto 25px auto;
            }
        }
    }

    .title2 {
        h4 {
            padding-bottom: 8px;
        }

        .title-inner2 {
            margin-bottom: 25px;
        }
    }

    .title3 {
        h4 {
            padding-bottom: 8px;
        }

        .title-inner3 {
            margin-bottom: 10px;
        }

        .line {
            margin-bottom: 25px;
        }
    }

    .title4 {
        .title-inner4 {
            padding-bottom: 15px;
        }

        .line {
            margin-bottom: 25px;
        }
    }

    .title-borderless {
        margin-bottom: 25px;
    }

    footer {
        &.footer-black {
            .below-section {
                padding-top: 105px;
            }
        }
    }

    header {
        &.header-6 {
            .mobile-search {
                display: inline-block;
            }
        }

        &.header-7 {
            .main-nav-center {
                .toggle-nav {
                    top: -94px;
                }
            }

            .pixelstrap {
                &.sm-horizontal {
                    >li {
                        >a {
                            color: black;

                            &:hover,
                            &:active {
                                color: var(--theme-color);
                            }
                        }
                    }
                }
            }
        }

        &.header-tools {
            .toggle-nav {
                padding-top: 25px;
                padding-bottom: 25px;
            }

            .pixelstrap {
                >li {
                    >a {
                        padding: 10px 15px !important;

                        &:hover,
                        &:active,
                        &:focus,
                        &.highlighted {
                            padding: 10px 15px;
                        }
                    }
                }

                li {
                    .lable-nav {
                        top: 5px;
                    }
                }
            }
        }

        &.video-header {
            #main-nav {
                position: absolute;
                right: 0;
                top: 3px;
            }

            .main-menu {
                .menu-right {
                    .icon-nav {
                        padding-right: 40px;
                    }
                }
            }
        }

        &.header-christmas {
            .pixelstrap {
                >li {
                    >a {
                        color: black !important;
                        padding: 10px 15px;

                        &:active,
                        &:focus,
                        &:hover,
                        &.highlighted {
                            padding: 10px 15px;
                        }
                    }
                }
            }
        }
    }

    .tools-parallax-product {
        &.full-banner {
            padding-top: 90px;
            padding-bottom: 90px;
        }

        .tools-description {
            h3 {
                font-size: 17px;
            }
        }
    }

    .form_search {
        display: none;
    }

    .full-banner {
        &.custom-space {
            padding: 60px 0;
        }
    }

    .tab-left {
        .theme-tab {
            display: block;

            .left-side {
                width: 100%;

                li {
                    width: unset;
                    border-bottom: none;
                    padding: 0 5px;

                    &:first-child {
                        border-top: none;
                    }
                }

                .tab-title {
                    margin-right: 0;
                    text-align: center;
                }
            }

            .tab-content-cls {
                width: 100%;
            }
        }
    }

    .absolute_banner {
        .collection-banner {
            .absolute-contain {
                h3 {
                    font-size: 22px;
                    margin-bottom: 0;
                }

                h4 {
                    font-size: 16px;
                }
            }
        }
    }

    .box-product {
        .full-box {
            .row {
                >div {
                    &:nth-last-child(-n + 2) {
                        margin-top: 30px;
                    }
                }
            }
        }
    }

    .header-gym {
        .toggle-nav {
            padding-top: 20px;
            padding-bottom: 20px;
        }
    }

    .gym-product {
        .part-cls {
            >div {
                &:nth-last-child(-n + 2) {
                    .product-box {
                        margin-top: 30px;
                    }
                }
            }
        }

        .partition-cls {
            >div {
                &:nth-child(-n + 4) {
                    margin-bottom: 0;
                }

                .product-box {
                    margin-top: 30px;
                }

                &:nth-child(-n + 2) {
                    .product-box {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .pet-parallax {
        .pet-decor {
            left: 45px;
            bottom: -105px;

            img {
                width: 200px;
            }
        }
    }

    .about-text {
        p {
            margin-bottom: 25px;
        }
    }

    .banner-timer {
        .timer {
            padding-top: 12px;
            padding-bottom: 12px;
            padding-left: 30px;
            bottom: 8px;
            margin-top: 38px;
        }
    }

    .footer-social {
        li {
            padding-right: 38px;
        }
    }

    .footer-theme2 {
        p {
            line-height: 25px;
        }

        .subscribe-block {
            padding: 10px 70px;
        }

        .footer-mobile-title {
            display: none !important;
        }

        .contact-details {
            li {
                line-height: 25px;
                padding: 0 10px;
            }
        }

        .footer-link {
            li {
                padding-right: 25px;
            }
        }

        &.section-light {
            .footer-block {
                .subscribe-white {
                    padding: 50px;
                }
            }
        }
    }

    .social-white {
        li {
            padding-left: 12px;
            padding-right: 12px;
        }
    }

    .service-block,
    .service-block1 {
        svg {
            width: 50px;
            height: 50px;
        }
    }

    .service-block1 {
        svg {
            margin-bottom: 15px;
        }
    }

    .category-border {
        div {
            .category-banner {
                .category-box {
                    h2 {
                        padding: 13px 27px;
                    }
                }
            }
        }
    }

    .collection-banner {
        .contain-banner {
            padding-left: 30px;
            padding-right: 30px;
        }
    }

    .category-bg {
        .image-block {
            &.even {
                &:after {
                    bottom: 87%;
                }
            }
        }

        .contain-block {
            &.even {
                &:after {
                    top: 87%;
                }
            }

            &:after {
                bottom: 87%;
            }
        }
    }

    .no-slider {
        .product-box {
            flex: 0 0 33.33%;
            max-width: calc(33.33% - 30px);
            margin: 0 15px 30px;
        }

        &.five-product {
            .product-box {
                width: 100%;
                flex: 0 0 33.33%;
                max-width: calc(33.33% - 30px);
                margin: 0 15px 30px;

                &:nth-last-child(-n + 5) {
                    margin: 0 15px 30px;
                }

                &:nth-last-child(-n + 3) {
                    margin: 0 15px 0;
                }
            }
        }

        &.five-product-row {
            .product-box {
                width: 100%;
                flex: 0 0 25%;
                margin-left: 15px;
                margin-right: 15px;
                max-width: calc(25% - 30px);
            }
        }
    }

    .absolute-product {
        .no-slider {
            .product-box {
                &:nth-last-child(-n + 4) {
                    margin: 0 15px 30px;
                }

                &:nth-last-child(-n + 2) {
                    margin: 0 15px 0;
                }
            }
        }
    }

    .theme-tab {
        .tab-title2 {
            font-size: 22px;

            &:after {
                top: 17px;
            }
        }

        .tab-content {
            .product-tab {
                .tab-box {
                    flex: 0 0 50%;
                    max-width: calc(50% - 10px);

                    &:nth-last-child(-n + 2) {
                        margin: 0 5px 0;
                    }

                    &:nth-last-child(-n + 4) {
                        margin: 0 5px 10px;
                    }

                    .product-box2 {
                        img {
                            height: 280px;
                        }
                    }
                }
            }
        }
    }

    .blog-section {
        .review-box {
            padding: 15px;

            .review-content {
                p {
                    margin-top: 0;
                }
            }
        }
    }

    .full-banner {
        .banner-contain {
            h2 {
                font-size: 85px;
            }

            h3 {
                font-size: 50px;
            }

            h4 {
                font-size: 22px;
                padding-top: 5px;
            }
        }

        .santa-img {
            img {
                width: 200px;
            }
        }

        &.feature-banner {

            .feature-object,
            .feature-object-right {
                li {
                    margin-right: 0;
                }
            }
        }
    }

    .instagram {
        .insta-decor {
            display: none;
        }
    }

    .logo-section {
        .logo-decor {
            display: none;
        }
    }

    .blog-section {
        .review-box {
            .santa-img {
                display: none;
            }
        }
    }

    .collection-banner {
        .contain-banner {
            &.banner-3 {
                padding-left: 25px;
                padding-right: 25px;

                h2 {
                    font-size: 22px;
                }

                h4 {
                    font-size: 12px;
                }
            }

            &.banner-4 {
                padding-left: 35px;
                padding-right: 35px;

                h2 {
                    font-size: 24px;
                }

                h4 {
                    font-size: 16px;
                }
            }

            h2 {
                font-size: 40px;
            }
        }
    }

    .home-slider {
        .home {
            // height: 70vh;
            height: 17vh;
        }

        .slider-contain {
            // height: 70vh;
            height: 17vh; //added
            justify-content: space-evenly; //added
            transform: translate(-45%, -110%); //added

            h1 {
                font-size: 50px;
            }

            &.slider-contain-banner {
                h1 {
                    font-size: 34px;
                }

                p {
                    font-size: 18px;
                    line-height: 30px;
                }
            }
        }
    }

    .height-85 {
        .home-slider {
            .home {
                height: 80vh;
            }

            .slider-contain {
                height: 80vh;
            }
        }
    }

    .background {
        .contain-bg {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }

    .theme-card {
        .offer-slider {
            .media {
                .media-body {
                    a {
                        h6 {
                            margin-right: 20px;
                        }
                    }

                    .rating {
                        i {
                            padding-right: 3px;
                        }
                    }
                }
            }
        }

        &.card-border {
            .offer-slider {
                padding-top: 0;
            }
        }
    }

    .full-box {
        .theme-card {
            .offer-slider {
                img {
                    padding: 15px 10px 15px 0;
                }

                .product-box2 {
                    .media {
                        img {
                            height: 250px;
                        }

                        .media-body {
                            padding-right: 5px;

                            .color-variant {
                                margin-top: 5px;

                                li {
                                    margin-top: 5px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .product-style-5 {
        &.product-box {
            .addtocart_btn {
                .add_cart {
                    width: 30px;
                    height: 30px;
                    font-size: 14px;
                    border-radius: 8px;
                }
            }
            .img-wrapper {
                margin-top: 10px;
            }
        }
    }

    .home-slider {
        .slider-details {
            width: 335px !important;
            height: 335px !important;

            h1 {
                font-size: 50px;
            }

            h2 {
                font-size: 36px;
            }

            .btn-white {
                margin-top: 0;
            }
        }

        &.fullpage {
            .home {
                .slider-contain {
                    h1 {
                        font-size: 35px;
                    }

                    p {
                        max-width: 450px;
                        font-size: 14px;
                    }

                    .btn-solid {
                        padding: 10px 15px;
                    }
                }
            }
        }
    }

    .about-section {
        h2 {
            font-size: 22px;
        }

        .about-text {
            p {
                line-height: 24px;
            }
        }

        .service {
            .service-block1 {
                svg {
                    margin-bottom: 5px;
                }

                h5 {
                    line-height: 18px;
                }
            }
        }

        .small-section {
            padding-top: 20px;
        }
    }

    .layout7-product {
        .product-box {
            .details-product {
                background-color: rgba($white, 0.5);
                padding: 5px;
            }
        }
    }

    .padding-bottom-cls {
        padding-bottom: 30px;
    }

    .layout-8 {
        padding: 0 40px;
    }

    .add_to_cart {

        &.top,
        &.bottom {
            left: -300px;
            top: 0;
            height: 100vh;
            width: 300px;

            .cart-inner {
                height: 100vh;
                overflow: auto;

                .cart_top {
                    padding: 20px !important;
                    margin-bottom: 20px;
                }

                .cart_media {
                    padding: 0 20px !important;
                    display: block;

                    .cart_product {
                        padding: 0;
                        width: 100%;
                        display: block;
                        overflow-y: hidden;

                        li {
                            min-width: 100%;
                            max-width: 100%;
                            margin-right: 0;
                            padding-bottom: 10px;
                        }
                    }

                    .cart_total {
                        padding: 0;
                        width: 100%;
                    }
                }
            }

            &.open-side {
                left: 0;
            }
        }
    }

    .animation-sec {
        .animated-icon {
            .icon-1 {
                width: 110px;
            }

            .icon-2 {
                width: 90px;
            }

            .icon-3 {
                left: 32%;
                width: 60px;
                top: 108px;
            }
        }
    }

    .cycle-box-row {
        >div {
            flex: 0 0 50%;
            max-width: 50%;
        }
    }

    .category-slide {
        .img-category {
            .img-sec {
                width: 85%;
            }
        }
    }

    .p-0-responsive {
        padding-top: 0 !important;
    }

    .single-prodct-feature {
        background-image: none !important;

        .upper-sec-feature {
            margin-bottom: 55px;

            .height-cls-res {
                >div {
                    height: 100% !important;
                }
            }
        }

        .lower-sec-feature {
            margin-bottom: 50px;
        }

        .home-slider {
            .home {
                height: auto !important;
            }
        }
    }

    .banner-style-1 {
        .custom-height {
            height: 314px;
        }

        .full-box {
            .theme-card {
                .offer-slider {
                    .product-box2 {
                        .media {
                            img.h-cls {
                                height: 170px;
                            }
                        }
                    }
                }
            }
        }
    }

    .button-dark {
        &.category-button {
            .btn {
                padding: 13px 10px;

                img {
                    width: 24px;
                }
            }
        }
    }

    .center-object-banner {
        .full-banner {
            &.feature-banner {
                .center-img {
                    text-align: center;

                    img {
                        width: 70%;
                    }
                }
            }
        }
    }

    .gradient-category {
        .gradient-border {
            height: 190px;
        }
    }

    // inner pages //
    .cart-section,
    .wishlist-section {
        tbody {
            tr {
                td {
                    min-width: 186px;
                }
            }
        }
    }

    .blog-detail-page {
        .comment-section {
            li {
                padding-top: 45px;
                padding-bottom: 45px;
            }
        }
    }

    .product-wrapper-grid {
        &.list-view {
            .product-box {

                .img-wrapper,
                .img-block {
                    width: 50%;
                }
            }
        }
    }

    .collection-product-wrapper {
        .product-top-filter {
            .product-filter-content {
                .search-count {
                    width: 100%;
                    border-left: 1px solid $round-border;
                    border-bottom: 1px solid $round-border;
                }

                .collection-view {
                    width: 20%;
                    padding-right: 0 !important;
                    border-left: 1px solid $round-border;
                }

                .collection-grid-view {
                    padding: 20px !important;
                    padding-left: 0 !important;
                    width: 20%;

                    ul {
                        li {
                            &:last-child {
                                display: none;
                            }
                        }
                    }
                }

                .product-page-filter {
                    width: 30%;
                }

                .product-page-per-view {
                    width: 30%;
                }
            }
        }
    }

    .cart-section {
        tbody {
            tr {
                td {
                    min-width: 140px;

                    .qty-box {
                        .input-group {
                            .form-control {
                                width: 52px;
                            }
                        }
                    }
                }
            }
        }

        tfoot {
            tr {
                td {
                    padding-right: 25px;
                }
            }
        }
    }

    .collection-collapse-block {
        .collection-collapse-block-content {
            .collection-brand-filter {
                .category-list {
                    li {
                        &:first-child {
                            margin-top: 15px;
                        }
                    }
                }
            }
        }
    }

    .collection-filter-block {
        .product-service {
            .media {
                .media-body {
                    h4 {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .product-right {
        h2 {
            font-size: 20px;
        }
    }

    .testimonial {
        .testimonial-slider {
            .media {
                .media-body {
                    padding: 30px;

                    p {
                        line-height: 1.5;
                    }
                }
            }
        }
    }

    .blog-page {
        .blog-media {
            margin-bottom: 20px;

            .blog-right {
                display: block;
                margin-top: 15px;

                p {
                    line-height: 1.3;
                }
            }
        }
    }

    .tab-product {
        .tab-content {
            &.nav-material {
                .single-product-tables {
                    table {
                        width: 50%;
                    }
                }
            }
        }
    }

    .product-description-box {
        .border-product {
            padding-top: 10px;
            padding-bottom: 15px;
        }

        .product-icon {
            .product-social {
                li {
                    padding-right: 4px;
                }
            }

            .wishlist-btn {
                span {
                    padding-left: 4px;
                }
            }
        }
    }

    .single-product-tables {
        &.detail-section table {
            width: 75%;
        }
    }

    .product-form-box {
        .border-product {
            padding-top: 10px;
            padding-bottom: 15px;
        }

        .timer {
            padding-left: 17px;

            span {
                width: 55px;
            }
        }

        .product-buttons {

            .btn-solid,
            .btn-outline {
                padding: 7px 13px;
            }
        }

        &.product-right {
            .timer {
                padding-left: 20px;

                span {
                    .padding-l {
                        padding-left: 14px;
                    }
                }
            }

            .product-buttons {
                .btn-solid {
                    padding: 5px 10px;
                }
            }
        }
    }

    .product-accordion {
        .single-product-tables {
            table {
                width: 70%;
            }
        }
    }

    .tab-product {
        .flex-column {
            flex-direction: unset !important;
            justify-content: center;
        }

        .nav-material {
            &.nav-tabs {
                border-bottom: 1px solid $round-border;
                border-right: 0;

                .nav-item {
                    .nav-link {
                        &.active {
                            background-color: unset;
                        }
                    }
                }
            }
        }
    }

    .tab-border {
        padding-top: 0;
    }

    .dashboard-section {
        .counter-section {
            .counter-box {
                padding: 20px;

                img {
                    height: 40px;
                    margin-right: 10px;
                }
            }
        }
    }

    .theme-card {
        .offer-slider {
            img {
                height: 120px;
            }
        }
    }

    .container-fluid {
        &.custom-container {
            padding-left: 50px;
            padding-right: 50px;
        }
    }

    .dark-box {
        >div {
            max-width: 25% !important;
            flex: 0 0 25% !important;
        }

        &.partition-five {
            >div {
                &:nth-child(-n + 4) {
                    margin-bottom: 20px;
                }
            }
        }
    }

    .sticky-bottom-cart {
        .selection-section {
            .form-group {
                width: 130px;
            }
        }
    }

    .product-box-timer {
        &.timer {
            span {
                width: 60px;
            }
        }
    }

    .product-right {
        .product-buttons {

            .btn-solid,
            .btn-outline {
                padding: 7px 15px;
            }
        }

        .timer {
            padding-left: 30px;
        }
    }

    .mordern-box {
        padding: 25px 20px;
    }

    .gradient-category {
        .gradient-border {
            height: 120px;
        }
    }

    // loader
    .layout-8 {
        .loader_skeleton {
            padding: 0 40px;
        }
    }

    .loader_skeleton {
        .category-ldr {
            .row {
                .category-block {
                    flex: 0 0 20%;
                    max-width: 20%;
                }
            }
        }

        .product-top-filter {
            .row {
                >div {
                    &:first-child {
                        border-left: 1px solid $bg-loader;
                        border-right: 1px solid $bg-loader;
                        border-bottom: 1px solid $bg-loader;
                    }

                    &:last-child {
                        border-right: 1px solid $bg-loader;
                    }
                }
            }
        }

        .tab-product {
            &.vertical-tab {
                ul {
                    li {
                        display: inline-block;
                        width: 16%;
                        height: 30px;
                        margin-top: 0;
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}

@media (min-width: 992px) {
    .demo-modal {
        .modal-lg {
            max-width: 1400px;
        }
    }

    .exit-modal {
        .modal-lg {
            max-width: 600px;
        }
    }
}

@media (max-width: 991px) and (min-width: 767px) {
    .partition-f {
        >div {
            &+div {
                &+div {
                    margin-top: 30px;
                }
            }
        }
    }

    .lookbook {
        .lookbook-block {
            .lookbook-dot {
                .dot-showbox {
                    width: 80px;

                    .dot-info {
                        h5 {
                            line-height: 15px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 991px) {
    h2 {
        font-size: 28px;
    }

    section,
    .section-t-space {
        padding-top: 50px;
    }

    .section-b-space {
        padding-bottom: 50px;
    }

    .header-style-1 {
        .navbar {
            .nav-link {
                padding-right: 18px;
                padding-left: 0;
                font-size: 14px;
            }
        }
    }

    .header-style-5 {
        &.color-style {
            &.style-classic {
                .main-menu {
                    .onhover-div {
                        padding-top: 25px;
                        padding-bottom: 25px;
                    }

                    .menu-left {
                        .navbar {
                            padding: 25px 45px 25px 0;
                        }
                    }
                }

                .toggle-nav {
                    padding-top: 25px;
                    padding-bottom: 25px;
                }
            }
        }
    }

    .portfolio-padding {
        padding-bottom: 20px;
    }

    .banner-offer {
        .collection-banner {
            .contain-banner {
                &.banner-3 {
                    h2  {
                        font-size: calc(16px + (34 - 16) * ((100vw - 320px) / (1920 - 320)));
                        margin: calc(2px + (10 - 2) * ((100vw - 320px) / (1920 - 320))) 0 !important;
                    }
                    h4 {
                        font-size: calc(14px + (20 - 14) * ((100vw - 320px) / (1920 - 320)));
                    }
                    h6 {
                        font-size: calc(12px + (20 - 12) * ((100vw - 320px) / (1920 - 320)));
                    }
                }
            }
        }
    }

    .small-section {
        padding: 30px 0;
    }

    .service-w-bg {
        .service-block {
            svg {
                margin-bottom: 0;
            }
        }
    }

    header {
        &.header-tools {
            position: relative;
            top: 0;

            .top-header {
                background-color: var(--theme-color);

                .container {
                    background-color: transparent;
                }
            }

            .logo-menu-part {
                background-color: white;

                >.container {
                    border-bottom: none;
                }
            }

            &.stickycls {
                position: fixed;
                background-color: white;
            }
        }

        &.header-christmas {
            position: relative;
            background-color: #e34041;
            margin-bottom: -50px;
        }
    }

    .tools_slider {
        .home-slider {
            .slider-contain {
                margin-top: 0;
            }
        }
    }

    footer {
        &.footer-5 {
            .footer-theme2 {
                .subscribe-block {
                    border-left: none;
                    border-right: none;
                }
            }
        }

        &.footer-black {
            .below-section {
                padding-top: 95px;
            }

            &.footer-light {
                .subscribe {
                    border-right: none;
                }
            }
        }

        &.footer-classic {
            .upper-footer {
                .small-section {
                    padding: 30px 0;
                }
            }
        }
    }

    .absolute_banner {
        .collection-banner {
            .absolute-contain {
                h3 {
                    font-size: 16px;
                }

                h4 {
                    font-size: 14px;
                }
            }
        }
    }

    .tools_product {
        .multiple-slider {
            >div {
                &:nth-child(-n + 2) {
                    margin-bottom: 0;
                }
            }
        }

        .tools-grey {
            margin-top: 30px;
        }

        .banner-tools {
            margin-left: 10px;
            margin-right: 10px;
        }
    }

    .tools-service {
        .service-block {
            +.service-block {
                border-left: none;
            }
        }

        .service-block,
        .service-block1 {
            svg {
                margin-bottom: 0;
            }
        }
    }

    .tools-brand {
        .row {
            margin: 0 10px;
            padding: 10px 0;
        }
    }

    .tools_slider {
        .home-slider {
            .slider-contain {
                margin-left: 40px;
            }

            .home {

                .tools-parts,
                .tools-parts1 {
                    margin-top: 20px;

                    img {
                        width: 180px;
                    }
                }

                .tools-parts {
                    right: 0;
                }

                .tools-parts1 {
                    right: 10%;
                }

                #tools-move2 {
                    right: 7%;
                }
            }
        }
    }

    .tools-parallax-product {
        .tools-description {
            h3 {
                text-align: center;
            }

            .tools-form {
                text-align: center;

                .search-box {
                    margin: 20px auto 20px;
                }

                .btn-find {
                    background-size: 1100px;
                }
            }
        }

        .tools-grey {
            order: -1;
            margin-bottom: 30px;
        }

        &.full-banner {
            padding-top: 75px;
            padding-bottom: 75px;
        }
    }

    .pet-parallax {
        .pet-decor {
            left: 34px;
            bottom: -86px;

            img {
                width: 165px;
            }
        }

        .banner-contain {
            p {
                max-width: 100%;
            }
        }
    }

    .service_slide {
        .service-home {
            position: relative;

            .service-block1 {
                padding: 0;
            }
        }
    }

    .service_slide {
        .partition4 {
            >div {
                +div {
                    margin-top: 0;

                    +div {
                        margin-top: 30px;
                    }
                }
            }
        }
    }

    .product-full-tab {
        padding-top: 50px !important;
    }

    .blog-section {
        .review-box {
            margin-top: 30px;

            .review-content {
                p {
                    margin-bottom: 45px;
                }
            }
        }
    }

    hr.style1 {
        margin-top: 12px;
        margin-bottom: 12px;
    }

    h4 {
        font-size: 16px;
    }

    .gym-banner {
        .collection-banner {
            .contain-banner {
                padding-left: 30px;
                padding-right: 30px;
            }
        }
    }

    .blog-details {
        h4 {
            margin-top: 17px;
            font-size: 13px;
        }

        p {
            font-size: 15px;
            line-height: 1.3;
            margin-top: 10px;
        }
    }

    .service-block,
    .service-block1 {
        svg {
            margin-bottom: 20px;
            height: auto;
            width: 46px;
        }
    }

    .service-style-border {
        &.tools-service {
            .service-block {
                +.service-block {
                    +.service-block {
                        margin-top: 20px;
                    }
                }
            }
        }
    }

    .partition_3 {
        >div {
            &+div {
                margin-top: 30px;
            }
        }
    }

    .partition4 {
        >div {
            &+div {
                &+div {
                    margin-top: 30px;
                }
            }
        }
    }

    .banner-timer {
        .banner-text {
            h2 {
                font-size: 20px;
            }
        }

        .timer {
            span {
                width: 65px;
            }

            p {
                font-size: 14px;
            }
        }
    }

    .about-text {
        p {
            line-height: 24px;
        }
    }

    .category-bg-section {
        .category-border-2 {
            .border-padding {
                &:nth-last-child(-n + 2) {
                    padding-top: 20px;
                }
            }
        }
    }

    .category-bg {
        &.onerow-cat {
            padding: 20px 30px;
        }
    }

    .footer-light {
        .subscribe {
            text-align: center;
            justify-content: center;
            border: none;
            margin-bottom: 5px;

            p {
                display: none;
            }
        }

        .subscribe-form {
            justify-content: center;
            margin-bottom: 0;
        }
    }

    .footer-theme {
        .col {
            max-width: 50%;
            flex-basis: unset;
        }

        .footer-logo {
            margin-bottom: 30px;
        }

        .sub-title {
            h4 {
                margin-bottom: 10px;
            }
        }
    }

    .footer-social {
        margin-top: 15px;
    }

    .subscribe-wrapper {
        order: 0;
        padding-top: 20px;
        padding-bottom: 20px;
    }

    .footer-theme2 {
        .footer-mobile-title {
            display: none !important;
        }

        h4 {
            padding-bottom: 10px;
        }

        .col {
            flex-basis: unset;
            padding: 20px 0;
        }

        &.section-light {
            .footer-block {
                h4 {
                    padding-bottom: 15px;
                }

                .subscribe-white {
                    border: none;
                }
            }
        }

        .footer-logo {
            margin-bottom: 20px;
        }

        .subscribe-block {
            border: none;
        }
    }

    .social-white {
        li {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .category-border {
        div {
            .category-banner {
                .category-box {
                    h2 {
                        padding: 5px 15px;
                        font-size: 30px;
                    }
                }
            }
        }
    }

    .category-bg {
        .image-block {
            &.even {
                &:after {
                    bottom: 85%;
                }
            }

            &:after {
                width: 35px;
            }

            &:hover {
                &:after {
                    height: 70px;
                }
            }
        }

        .contain-block {
            h2 {
                font-size: 20px;
                margin-top: 8px;
            }

            &:after {
                width: 35px;
            }

            &.even {
                &:after {
                    top: 85%;
                }
            }

            .category-btn {
                margin-bottom: 15px;
                margin-top: 15px;
                letter-spacing: 0.2em;
                padding: 10px 15px;
            }

            &:after {
                bottom: 85%;
            }

            &:hover {
                &:after {
                    height: 70px;
                }
            }
        }
    }

    .no-slider {
        .product-box {
            flex: 0 0 50%;
            max-width: calc(50% - 30px);
            margin: 0 15px 30px !important;

            &:nth-last-child(-n + 2) {
                margin: 0 15px 0 !important;
            }
        }

        &.five-product {
            .product-box {
                flex: 0 0 50%;
                max-width: calc(50% - 30px);
                margin: 0 15px 30px !important;

                &:nth-last-child(-n + 2) {
                    margin: 0 15px 0 !important;
                }
            }
        }

        &.five-product-row {
            .product-box {
                flex: 0 0 33.33%;
                max-width: calc(33.33% - 30px);
                margin-left: 15px;
                margin-right: 15px;
            }
        }
    }

    .full-banner {
        padding-top: 180px;
        padding-bottom: 180px;

        &.parallax-layout {
            padding-top: 180px;
            padding-bottom: 180px;
        }

        &.feature-banner {

            .feature-object,
            .feature-object-right {
                li {
                    &:nth-child(2) {
                        padding-right: 0;
                        padding-left: 0;
                    }

                    &:nth-child(3) {
                        padding-right: 0;
                        padding-left: 0;
                    }
                }
            }

            .center-img {
                display: none;
            }

            .feature-object-right {
                margin-left: 0;
            }

            .banner-decor {

                .left-img,
                .right-img {
                    img {
                        width: 100px;
                    }
                }
            }

            h2 {
                margin-bottom: 30px;
            }
        }

        .banner-contain {
            h2 {
                font-size: 60px;
            }

            h3 {
                font-size: 35px;
            }

            h4 {
                font-size: 20px;
                padding-top: 5px;
            }
        }

        .decor {
            display: none;
        }
    }

    .center-object-banner {
        .full-banner {
            &.feature-banner {
                h2 {
                    margin-bottom: 30px;
                }
            }
        }
    }

    .collection-banner {
        .contain-banner {
            &.banner-3 {
                padding-left: 30px;
                padding-right: 30px;

                h2 {
                    font-size: 20px;
                }
            }

            &.banner-4 {
                padding-left: 40px;
                padding-right: 40px;

                h2 {
                    font-size: 18px;
                }

                h4 {
                    font-size: 18px;
                }
            }

            h2 {
                font-size: 25px;
            }
        }
    }

    .collection-collapse-block {
        border-bottom: 1px solid lighten($black, 86.5%) !important;
    }

    .service_slide {
        .home-slider {
            .slider-contain {
                height: 65vh;
            }
        }
    }

    .margin-add {
        margin-top: 0;
    }

    .home-slider {
        .home {
            // height: 65vh;
            height: 17vh; // edited
            max-height: 17vh;
        }

        .slider-contain {
             // height: 65vh;
             height: 17vh; // edited
             justify-content: space-evenly; // edited
             transform: translate(-50%, -105%); // edited

            h1 {
                font-size: 36px;
            }

            .btn-solid,
            .btn-outline {
                margin-top: 17px;
            }

            &.slider-contain-banner {
                h1 {
                    font-size: 24px;
                }

                p {
                    margin-top: 10px;
                    line-height: 28px;
                }
            }
        }

        .slider-details {
            top: 20%;
            padding: 0;
            width: 260px !important;
            height: 260px !important;

            h1 {
                line-height: 1;
                font-size: 30px;
                margin: 5px 0;
            }

            h2 {
                font-size: 24px;
            }

            h3 {
                font-size: 20px;
            }

            h4 {
                font-size: 18px;
                line-height: 1;
                margin-bottom: 2px;
            }

            .btn-white {
                padding: 7px 14px;
                margin-top: 5px;
            }
        }

        &:hover {
            .slick-next {
                right: 90px;
            }

            .slick-prev {
                left: 90px;
            }
        }
    }

    .height-85 {
        .home-slider {
            .home {
                height: 70vh;
            }

            .slider-contain {
                height: 70vh;
            }
        }
    }

    .background {
        .contain-bg {
            padding-top: 25px;
            padding-bottom: 25px;

            h4 {
                font-size: 16px;
            }
        }
    }

    .theme-tab {
        .tab-content {
            .product-tab {
                .tab-box {
                    .product-box2 {
                        img {
                            height: 210px;
                        }
                    }
                }
            }
        }
    }

    .beauty-about {
        .about-text {
            p {
                text-align: center;
            }
        }
    }

    .theme-card {
        .offer-slider {
            img {
                padding: 10px 10px 10px 0;
            }
        }

        &.card-border {
            .offer-slider {
                img {
                    padding: 10px 10px 10px 30px;
                }
            }
        }
    }

    .full-box {
        .center-slider {
            .offer-slider {
                .product-box {
                    .product-info {
                        h4 {
                            padding-bottom: 5px;
                        }

                        .btn-outline {
                            padding: 7px 20px;
                        }
                    }
                }
            }
        }

        .theme-card {
            .offer-slider {
                .product-box2 {
                    .media {
                        img {
                            height: 150px;
                        }

                        .media-body {
                            .color-variant {
                                li {
                                    margin-top: 0;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .layout-8 {
        .layout-8-bg {
            padding: 0;
        }
    }

    .alert {
        max-width: 45% !important;
    }

    .theme-modal {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    .modal-bg {
                        .offer-content {
                            img {
                                margin-bottom: 20px;
                            }

                            h2 {
                                margin-bottom: 18px;
                            }
                        }
                    }
                }
            }
        }

        &.cart-modal {
            .modal-dialog {
                .modal-content {
                    .modal-body {
                        .modal-bg {
                            padding: 15px;

                            &.addtocart {
                                .media {
                                    .media-body {
                                        .buttons {
                                            a {
                                                font-size: 13px;
                                                padding: 4px 10px;
                                                margin: 3px 6px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.exit-modal {
            .modal-dialog {
                .modal-content {
                    .modal-body {
                        .modal-bg {
                            padding: 20px;
                        }
                    }
                }
            }
        }
    }

    .parallax-cls {
        .banner-contain {
            background-color: rgba($white, 0.5);
            padding: 35px;
        }

        section {
            .banner-contain {
                margin-top: 0;
            }
        }
    }

    .about-section {
        padding-top: 30px;

        h2 {
            text-align: center;
        }
    }

    .product-box {
        .cart-info {
            bottom: 20px;
        }
    }

    .absolute-banner {
        margin-top: -60px;

        &.search-section {
            margin-top: 0;

            .absolute-bg {
                .row {
                    >div {
                        margin-bottom: 0;

                        &.search-col {
                            margin-bottom: 0;

                            .search-btn {
                                width: 100%;
                                margin: 0 auto;
                            }
                        }
                    }
                }
            }
        }
    }

    .custom-cake {
        .steps-media {
            img {
                width: 42px;
            }
        }
    }

    header {
        &.header-style {
            &.header-tools {
                .top-header {
                    background-color: #ffffff;

                    .onhover-dropdown {
                        &:before {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    .main-feature {
        .key-feature {
            margin-top: -30px;

            >div {
                margin-top: 30px;
            }

            .theme-collection {
                .image-contain {
                    padding: 30px;
                }
            }
        }
    }

    .dark-box {
        >div {
            max-width: 33.33% !important;
            flex: 0 0 33.33% !important;
        }

        &.partition-five {
            >div {
                &:nth-child(-n + 4) {
                    margin-bottom: 0;
                }

                &:nth-child(-n + 3) {
                    margin-bottom: 20px;
                }
            }
        }

        &.four-product {
            >div {
                max-width: 33.33% !important;
                flex: 0 0 33.33% !important;
            }
        }
    }

    .banner-style-2 {
        &.absolute-banner {
            .absolute-bg {
                .collection-banner {
                    .absolute-img {
                        img {
                            width: 29%;
                            left: 26px;
                            top: -12px;
                        }
                    }
                }
            }
        }
    }

    .service-style-border {
        .service-block {
            justify-content: flex-start;
        }
    }

    .product-left-title {
        margin-bottom: 30px;

        h3 {
            max-width: 100%;
        }

        &.right-content {
            text-align: left;

            .slick-custom-arrow {
                justify-content: flex-start;
            }
        }
    }

    .cookie-bar {
        &.left-bottom {
            display: none;
            width: 100%;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 20px;

            img {
                display: none;
            }

            p {
                margin-bottom: 6px;
            }

            .btn-close {
                display: none;
            }

            &.subscribe-box {
                left: 20px;
                bottom: 20px;

                .btn-close {
                    display: inline-block;
                }
            }
        }
    }

    .deal-category {
        .deal-content {
            h2 {
                font-size: 22px;
            }
        }
    }

    .shop-sidebar-demo {
        .shop-sidebar {
            height: 100vh;
            top: 0;
            width: 290px;
            left: -300px;
            transition: all 0.5s ease;
            z-index: 6;
            &.show {
                left: 0;
                transition: all 0.5s ease;
            }
        }
        .shop-main {
            margin-left: 0;
            width: 100%;
        }
    }

    .sidebar-shop-footer {
        margin-left: 0;
    }

    // inner pages //
    .login-page {
        .authentication-right {
            height: auto;
        }
    }

    .testimonial {
        .testimonial-slider {
            .media {
                .media-body {
                    padding: 0 30px;
                    height: 100%;
                    align-self: center;

                    p {
                        margin-bottom: 0;
                    }
                }
            }

            .slick-track {
                .slick-slide {
                    &:nth-child(even) {
                        .media {
                            border-left: none;
                            padding-left: 0;
                        }
                    }
                }
            }
        }
    }

    .blog-detail-page {
        .blog-advance {
            ul {
                margin-bottom: 20px;
            }

            p {
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .top-banner-content {
        h4 {
            font-size: 20px;
        }
    }

    .collection-product-wrapper {
        .product-top-filter {
            border-top: none;

            .product-filter-content {
                .search-count {
                    border-top: 1px solid $round-border;
                }

                .collection-view {
                    display: none;
                }

                .collection-grid-view {
                    display: none;
                }

                .product-page-per-view,
                .product-page-filter {
                    width: 50%;

                    &:before {
                        right: 15px !important;
                    }

                    select {
                        border-left: 1px solid $round-border;
                        padding: 21px 34px 21px 34px;
                        border-top: none;
                        border-bottom: none;
                    }
                }

                .product-page-per-view {
                    select {
                        border-right: none !important;
                    }
                }
            }

            .popup-filter {

                .product-page-per-view,
                .product-page-filter {
                    border-top: 1px solid $round-border;
                }
            }
        }
    }

    .filter-main-btn {
        display: block;
    }

    .collection-filter {
        position: fixed !important;
        height: 100vh;
        top: 0;
        left: -350px;
        background-color: white;
        z-index: 99;
        overflow-y: scroll;
        padding: 15px 0 15px 15px;
        width: 320px;
        transition: all 0.5s ease;
        box-shadow: 1px 0 5px #ddd;
        display: block;

        .collection-sidebar-banner {
            text-align: center;
        }

        .theme-card {
            padding-left: 30px;
            padding-right: 30px;
        }

        .collection-sidebar-banner {
            padding: 0 30px;
        }

        &.open {
            left: 0;
        }
    }

    .collection-filter-block {
        border: none;
        background-color: transparent;
    }

    .collection-mobile-back {
        display: block;
    }

    .collection {
        .section-t-space {
            padding-top: 30px;
        }

        .partition-collection {
            >div {
                &:nth-last-child(1) {
                    margin-top: 30px;
                }

                &:nth-last-child(2) {
                    margin-top: 30px;
                }
            }
        }
    }

    .collection-product-wrapper {
        .product-top-filter {
            .popup-filter {
                .open-popup {
                    &.open {
                        min-width: unset;
                        border: none;
                    }
                }
            }
        }
    }

    .right-login {
        margin-top: 30px;
    }

    .contact-page {
        .map {
            iframe {
                height: 350px;
            }
        }

        .contact-right {
            padding-bottom: 0;

            ul {
                li {
                    padding-left: 0;
                    border-bottom: 1px solid $round-border;
                    text-align: center;
                    padding-bottom: 10px;
                    margin-top: 15px;

                    .contact-icon {
                        position: relative;
                        margin: 0 auto;
                        border-right: 0;
                    }

                    p {
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    .cart-section {
        .cart-table {
            thead {
                th {
                    &:last-child {
                        display: none;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    min-width: 135px;

                    &:last-child {
                        display: none;
                    }
                }
            }
        }

        .cart-buttons {
            >div {
                &:last-child {
                    padding-right: 15px;
                }
            }
        }

        tfoot {
            tr {
                td {
                    padding-right: 0;
                }
            }
        }
    }

    .wishlist-section {
        tbody {
            tr {
                td {
                    min-width: 138px;
                }
            }
        }
    }

    .product-right {
        text-align: center;
        margin: 20px 0 10px 0;

        h2 {
            margin-top: 15px;
        }

        .rating-section {
            justify-content: center;
        }

        .detail-section,
        .product-icon {
            justify-content: center;
        }

        .product-description {
            .qty-box {
                justify-content: center;
            }
        }

        .size-text {
            text-align: left;
        }

        .timer {
            text-align: left;
        }

        .product-icon {
            .product-social {
                li {
                    padding-right: 20px;
                }
            }
        }
    }

    .collection-product-wrapper {
        .product-top-filter {
            .popup-filter {
                .sidebar-popup {
                    width: 55%;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    text-align: center;
                    margin-bottom: 20px;
                    background: none var(--theme-color);

                    .open-popup {
                        text-align: left;
                    }

                    >a {
                        color: $white;
                    }

                    .collection-filter {
                        height: 100vh;
                        left: -350px;
                        transition: all 0.5s ease;
                    }
                }

                .search-count {
                    width: 100%;
                    padding: 10px 0;
                    text-align: center;
                }

                .collection-view,
                .collection-grid-view {
                    display: none;
                }

                .product-page-per-view,
                .product-page-filter {
                    width: 50%;

                    select {
                        padding-top: 10px;
                        padding-bottom: 10px;
                    }

                    &:before {
                        top: 13px;
                    }
                }

                .product-page-filter {
                    &:before {
                        left: unset;
                        right: 35px;
                    }
                }
            }
        }
    }

    .tab-product {
        padding-top: 0;
    }

    .tab-product {
        .nav-material {
            &.nav-tabs {
                justify-content: center;
            }
        }
    }

    .pro_sticky_info {
        border: none;
        padding: 0;
        margin-top: 0;
        margin-bottom: -20px;
    }

    .product-form-box {
        margin-bottom: 10px;
    }

    .dashboard-left {
        position: fixed;
        height: 100vh;
        top: 0;
        left: -350px;
        background-color: white;
        z-index: 99;
        padding: 0 35px 35px;
        max-width: 350px;
        transition: all 0.5s ease;
        box-shadow: 0 0 8px 0 $round-border;

        .block-title {
            h2 {
                display: none;
            }
        }

        .block-content {
            border: none;
            padding: 0;
            margin-top: 20px;

            ul {
                li {
                    &:hover {
                        padding-left: 0;
                    }
                }
            }
        }
    }

    .account-sidebar {
        display: block;
    }

    .typography_section {
        .row {
            >div {
                &:first-child {
                    .typography-box {
                        &:last-child {
                            margin-bottom: 30px;
                        }
                    }
                }
            }
        }
    }

    .product-slick,
    .rtl-product-slick,
    .product-right-slick,
    .rtl-product-right-slick {

        .slick-prev,
        .slick-next {
            opacity: 1;
        }

        .slick-prev {
            left: 20px;
        }

        .slick-next {
            right: 20px;
        }
    }

    .order-up {
        order: -1;
    }

    .bundle {
        .bundle_img {
            justify-content: center;
        }

        .bundle_detail {
            .theme_checkbox {
                padding: 0 20%;
            }
        }
    }

    .checkout-page {
        .checkout-form {
            .checkout-details {
                margin-top: 30px;
            }
        }
    }

    .vendor-cover {
        .bg-size {
            height: 270px;
        }
    }

    .vendor-profile {
        .profile-left {
            display: block;

            .profile-image {
                margin: 0 auto;
                width: 100%;
            }

            .profile-detail {
                margin: 0 auto;
                text-align: center;
                margin-left: 0;
                padding-left: 0;
                border-left: none;
                border-top: 1px solid #efefef;
                padding-top: 15px;
                width: 100%;
                margin-top: 15px;
            }

            .vendor-contact {
                width: 100%;
                text-align: center;
                margin-left: 0;
                padding-left: 0;
                border-left: none;
                border-top: 1px solid #efefef;
                padding-top: 15px;
                margin-top: 15px;

                .footer-social {
                    justify-content: center;

                    li {
                        padding: 0 5px;
                    }
                }
            }
        }
    }

    .become-vendor {
        h4 {
            margin-bottom: 20px;
        }

        .step-bg {
            .row {
                margin-left: -15px;
                margin-right: -15px;

                >div {
                    padding-left: 15px;
                    padding-right: 15px;

                    &:nth-child(2) {
                        margin-top: 0;
                        margin-bottom: 30px;
                    }

                    &:nth-child(1) {
                        margin-bottom: 30px;
                    }

                    &:nth-child(3) {
                        margin-bottom: 0;
                    }
                }
            }

            &:before {
                transform: rotate(90deg);
            }
        }
    }

    .dashboard-section {
        .dashboard-sidebar {
            .faq-tab {
                .nav-tabs {
                    display: flex;
                    width: 100%;
                    background-color: $top-header;
                    overflow: auto;
                    flex-wrap: unset;

                    .nav-item {
                        white-space: nowrap;
                    }
                }
            }
        }

        .counter-section {
            margin-top: 20px;
        }
    }

    .faq-tab {
        .nav-tabs {
            .nav-item {
                .nav-link {
                    &.active {
                        border-bottom: 2px solid var(--theme-color);
                        border-right: none;
                    }
                }
            }
        }
    }

    .bg-title {
        .theme-tab {
            .bg-title-part {
                margin-top: 30px;
            }
        }
    }

    .tracking-page {
        .wrapper {
            .arrow-steps {
                .step {
                    min-width: 130px;
                }
            }
        }
    }

    .top-filter {
        .collection-collapse-block {
            padding-bottom: 20px;
        }
    }

    .sticky-bottom-cart {
        .selection-section {
            .form-group {
                width: 170px;
            }
        }
    }

    .order-success-sec {
        margin-top: 20px;

        .row {
            margin-bottom: -20px;

            >div {
                margin-bottom: 20px;
            }
        }
    }

    .tracking-page {
        .order-map {
            margin-top: 20px;
            padding-bottom: 20px;
        }
    }

    .tools_product {
        .multiple-slider {
            margin-bottom: 0;
        }
    }

    .theme-invoice-4 {
        .invoice-wrapper {
            .invoice-footer {
                ul {
                    li {
                        i {
                            margin-right: 10px;
                        }
                    }
                }
            }
        }
    }

    .element-page {
        .left-sidebar {
            .sticky-sidebar {
                position: fixed !important;
                width: 290px;
                height: 100vh;
                overflow: auto;
                left: -300px;
                transition: all 0.5s ease;
                z-index: 9;
                background-color: white;
                top: 0;
                box-shadow: 1px 0 5px #ddd;

                .doc-nav {
                    padding: 25px;
                }

                .collection-mobile-back {
                    padding: 25px;
                }

                &.show {
                    left: 0;
                    transition: all 0.5s ease;
                }
            }
        }
    }

    // loader
    .loader_skeleton {
        .collection-banner {
            .contain-banner {
                padding-left: 40px;
                padding-right: 40px;
            }
        }

        .category-ldr {
            .row {
                .category-block {
                    flex: 0 0 25%;
                    max-width: 25%;
                }
            }
        }

        .product-top-filter {
            border-top: 1px solid $bg-loader;

            .row>div {
                &:nth-child(3) {
                    border-right: 1px solid $bg-loader;
                }
            }
        }

        .product-page {
            .product-right {

                h2,
                h3,
                h4 {
                    margin-left: auto;
                    margin-right: auto;
                }

                .btn-group {
                    justify-content: center;
                }
            }
        }

        .tab-product {
            margin-top: 25px !important;
        }

        .blog-page {
            .order-sec {
                .product-box {
                    .media {
                        display: block;
                    }

                    .img-wrapper {
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media (max-width: 767px) {
    .container-fluid {
        &.custom-container {
            padding-left: 30px;
            padding-right: 30px;
        }
    }

    .title-basic {
        display: block;

        .timer {
            margin-left: 0;
            margin-top: 15px;
            padding-left: 30px;

            p {
                font-size: 16px;
            }

            span {
                width: 60px;

                .padding-l {
                    padding-left: 12px;
                }
            }
        }
    }

    .space_sm {
        padding-top: 0 !important;
    }

    header {
        &.header-5 {
            &.left-sidebar-header {
                position: relative;
            }
        }
    }

    .header-style-1 {
        .main-menu {
            .brand-logo {
                padding-top: 15px;
                padding-bottom: 15px;
            }
        }

        .toggle-nav {
            top: -43px;
        }
    }

    .header-style-5 {
        &.color-style {
            .main-menu {
                .menu-right {
                    .icon-nav {
                        li {
                            padding-left: 12px;
                        }
                    }
                }
            }

            .top-header {
                .header-dropdown {
                    >li {
                        padding-left: 12px;
                    }
                }
            }
        }
    }

    .theme-modal {
        &.cart-modal {
            .product-section {
                display: none;
            }
        }
    }

    .addtocart_count {
        .product-box {
            &:hover {
                .cart-info {
                    a {

                        &:nth-child(1),
                        &:nth-child(2),
                        &:nth-child(3) {
                            i {
                                animation: none;
                            }
                        }
                    }
                }
            }
        }

        .center-slider {
            .offer-slider {

                .add-button,
                .cart-info {
                    display: none;
                }

                .product-box {
                    .product-detail {
                        margin-top: 25px;
                    }
                }
            }
        }
    }

    .absolute_banner {
        margin-bottom: 0;

        .collection-banner {
            .absolute-contain {
                bottom: 25px;

                h3 {
                    font-size: 20px;
                }

                h4 {
                    font-size: 16px;
                }
            }
        }
    }

    .box-product {
        .full-box {
            .row {
                >div {
                    &:nth-last-child(-n + 3) {
                        margin-top: 30px;
                    }
                }
            }
        }
    }

    .advertise-section {
        .full-banner {
            .banner-contain {
                h2 {
                    font-size: 60px;
                    margin-top: -10px;
                }
            }
        }
    }

    .absolute-banner {
        margin-top: -60px;

        &.search-section {
            margin-top: 0;

            .absolute-bg {
                .row {
                    >div {
                        margin-bottom: 20px;

                        &.search-col {
                            margin-bottom: 0;

                            .search-btn {
                                width: 70%;
                                margin: 0 auto;
                            }
                        }
                    }
                }
            }
        }
    }

    footer {
        &.footer-classic {
            .upper-footer {
                .small-section {
                    padding: 20px 0;
                }
            }

            .subscribe-form {
                .form-control {
                    padding: 8px;
                }
            }
        }
    }

    .footer-style-1 {
        .contact-list {
            margin-bottom: 10px;
        }

        .footer-contant {
            .footer-social {
                li {
                    padding-right: 5px;
                }
            }
        }
    }

    .insta-title {
        width: 170px;
        height: 30px;

        h4 {
            font-size: 14px;
        }
    }

    .tools_slider {
        .home-slider {
            .home {

                .tools-parts,
                .tools-parts1 {
                    display: none;
                }
            }

            .slider-contain {
                margin-left: 0;
                justify-content: center !important;
            }
        }
    }

    .tools-service {
        .service-block {
            +.service-block {
                margin-top: 0;

                +.service-block {
                    margin-top: 20px;
                }
            }
        }
    }

    .tools-grey {
        .product-box {
            .img-wrapper {
                padding-bottom: 35px;
            }

            .cart-info {
                bottom: 10px;

                button {
                    font-size: 0;
                    width: unset;
                    background-color: #eef0f1;

                    i {
                        display: block;
                    }

                    &:hover {
                        i {
                            color: $white;
                        }
                    }
                }

                .mobile-quick-view {
                    display: block;
                    margin-left: 5px;
                }
            }

            .quick-view-part {
                display: none;
            }

            &:hover {
                .img-wrapper {
                    .front {
                        img {
                            opacity: 1;
                        }
                    }
                }

                .ribbon {
                    display: block;
                }
            }
        }
    }

    .tools-parallax-product {
        .tools-grey {

            .slick-prev,
            .slick-next {
                display: none !important;
            }
        }

        &.full-banner {
            padding-top: 60px;
            padding-bottom: 60px;
        }
    }

    .bag-product {

        .product-box,
        .product-wrap {
            .img-wrapper {
                margin-bottom: 0;
            }

            .product-info {
                .add-btn {
                    bottom: 0;
                    position: relative;

                    .btn {
                        padding: 4px 5px;
                        margin-top: 7px;
                    }
                }
            }

            &.product-style-2 {
                .product-info {
                    .add-btn {
                        margin-top: 8px;
                    }

                    .color-variant {
                        padding-top: 5px;
                    }
                }
            }
        }
    }

    .pet-parallax {
        .pet-decor {
            display: none;
        }
    }

    .j-box {
        .product-box {
            .cart-info {
                bottom: 0;
                position: relative;
                opacity: 1;

                a {
                    i {
                        padding: 10px;
                    }
                }
            }
        }

        &.style-box {
            .product-box {
                .product-detail {
                    padding-top: 0;
                }
            }
        }
    }

    .pets-box {
        .product-box {
            .img-wrapper {
                .cart-info {
                    transform: none;

                    i {
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .service-w-bg {
        .service-block {
            svg {
                margin-bottom: 15px;
            }
        }
    }

    .layout3-menu {
        .main-menu {
            .brand-logo {
                img {
                    height: 25px;
                }
            }
        }
    }

    .game-product {
        .product-box {
            .cart-info {
                right: 5px;

                a {
                    i {
                        margin: 7px 0;
                        padding: 7px;
                        font-size: 14px;
                    }
                }
            }

            .add-button {
                bottom: 0;
            }

            .img-wrapper {
                padding-bottom: 37px;
            }

            &:hover {
                .cart-info {
                    a {
                        &:nth-child(1) {
                            i {
                                animation: none;
                            }
                        }
                    }
                }
            }
        }

        .theme-tab {

            .tab-title,
            .tab-title2 {
                .current {
                    a {
                        border-bottom: none;
                        padding-bottom: 0;
                    }
                }
            }
        }
    }

    .gym-product {
        .product-box {
            .img-wrapper {
                .cart-info {
                    display: block;
                    height: unset;
                    top: unset;
                    left: unset;
                    right: 0;

                    i {
                        background: none;
                        color: $grey6;
                    }

                    button,
                    a {
                        &:hover {
                            i {
                                color: var(--theme-color);
                            }
                        }
                    }
                }
            }

            &:hover {
                .img-wrapper {
                    .cart-info {
                        button {
                            animation: none;
                        }

                        a {
                            &:nth-child(2) i {
                                animation: none;
                            }

                            &:nth-child(3) i {
                                animation: none;
                            }

                            &:nth-child(4) i {
                                animation: none;
                            }
                        }
                    }
                }
            }
        }
    }

    .game-banner {
        .banner-timer {
            .banner-text {
                background-color: transparent;
            }
        }
    }

    .detail-cannabis {
        .detail_section {
            margin-top: 30px;
            text-align: center;
        }

        .row {
            >div {
                &:first-child {
                    .detail_section {
                        margin-top: 0;
                    }
                }
            }
        }
    }

    .border-box {
        &.tools-grey {
            .product-box {
                .img-wrapper {
                    padding-bottom: 0;
                }

                .cart-info {

                    a,
                    button {
                        background-color: $white;
                    }
                }
            }
        }
    }

    .service_slide {
        .home-slider {
            .slider-contain {
                height: 60vh;
            }
        }
    }

    .portfolio-section {
        &.metro-section {
            .product-box {
                .cart-wrap {
                    a {
                        i {
                            opacity: 1;
                            font-size: 16px;
                            padding: 5px;
                        }
                    }

                    button {
                        i {
                            font-size: 16px;
                            padding: 5px;
                        }
                    }
                }

                .product-detail {
                    opacity: 1;
                    bottom: 15px;
                    background-color: rgba($white, 0.5);

                    h6 {
                        color: $black;
                    }
                }

                &:hover {
                    .product-detail {
                        opacity: 1;
                    }

                    .cart-wrap {
                        a {

                            &:nth-child(2),
                            &:nth-child(3),
                            &:nth-child(4) {
                                i {
                                    animation: none;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .bg-title {
        .theme-tab {
            .bg-title-part {
                display: block;
                text-align: center;

                .tab-title {
                    margin-left: unset;
                    text-align: center;

                    li {
                        padding-left: 5px;
                        padding-right: 5px;
                    }

                    &.w-bg {
                        margin-top: 15px;
                    }
                }
            }
        }
    }

    header {
        &.header-metro {
            .metro {
                .layout3-menu {
                    max-width: 540px;
                }
            }
        }

        &.left-header {
            .top-header {
                .header-dropdown {
                    li {
                        padding: 15px;
                    }
                }
            }

            .main-menu {
                .menu-right {
                    .icon-nav {
                        li {
                            padding-left: 15px;
                        }
                    }
                }
            }
        }
    }

    footer {
        &.footer-5 {
            .sub-footer {
                >.container {
                    border-top: none;
                }
            }

            .dark-layout {
                .footer-title {
                    border-bottom: 1px solid $white;
                }
            }
        }

        &.footer-black {
            .upside {
                .small-section {
                    .center-thing {
                        display: inline-block;
                        width: 100%;
                    }
                }
            }

            .footer-title {
                h4 {
                    color: $white;
                }
            }

            &.footer-light {
                .subscribe {
                    height: auto;
                    margin-bottom: 10px;
                }

                .subscribe-form {
                    height: auto;
                }
            }

            .below-section {
                padding-top: 85px;
            }
        }
    }

    section,
    .section-t-space {
        padding-top: 40px;
    }

    .section-b-space {
        padding-bottom: 40px;
    }

    .portfolio-padding {
        padding-bottom: 10px;
    }

    .product-full-tab {
        padding-top: 30px !important;
    }

    .product-box {
        &.single-product {
            .product-detail {
                .btn-solid {
                    padding: 8px 36px;
                }
            }

            .color-variant {
                padding-top: 10px;

                .nav-item {
                    width: 20px !important;
                    height: 20px !important;
                    margin: 0 6px;
                }
            }
        }

        .cart-info {
            &.bg-color-cls {
                right: 0;
            }
        }
    }

    .small-section {
        padding: 20px 0;
    }

    .theme-tab {

        .tab-title,
        .tab-title2 {
            a {
                text-transform: capitalize;
            }
        }
    }

    .about-cls {
        .service {
            &.border-section {
                border-bottom: none;
            }

            &.small-section {
                padding-bottom: 0;
            }
        }
    }

    .service-block {
        .media {
            display: block;
            text-align: center;
            padding-top: 0;
            padding-bottom: 0;
        }

        svg {
            margin-right: 0;
        }

        +.service-block {
            border-left: 0;
            margin-top: 20px;
        }

        &:last-child {
            .media {
                .media-body {
                    p {
                        margin-bottom: 5px;
                    }
                }
            }
        }
    }

    .full-banner {
        &.custom-space {
            padding: 30px 0;
        }

        .custom-size {
            &.banner-contain {
                h2 {
                    font-size: calc(30px + (55 - 30) * ((100vw - 320px) / (767 - 320)));
                    margin-bottom: 0;
                }

                h3 {
                    font-size: calc(18px + (28 - 18) * ((100vw - 320px) / (767 - 320)));
                }

                .btn {
                    margin-top: 0;
                    padding: 7px 19px;
                    font-size: calc(12px + (14 - 12) * ((100vw - 320px) / (767 - 320)));
                }
            }
        }
    }

    .partition2 {
        margin-bottom: -25px;

        >div {
            margin-bottom: 25px;
        }
    }

    .partition3 {
        >div {
            &+div {
                margin-top: 30px;
            }
        }
    }

    .partition4 {
        >div {
            &+div {
                margin-top: 30px;
            }
        }
    }

    .center-slider {
        border-left: none;
        border-right: none;
    }

    .about-text {
        p {
            line-height: 25px;
        }
    }

    .banner-timer {
        padding-top: 5px;
        padding-bottom: 5px;

        .banner-text {
            padding-top: 15px;
            padding-bottom: 15px;
            background-color: rgba(255, 255, 255, 0.65);

            h2 {
                font-size: 15px;
            }
        }

        .timer-box {
            text-align: center;
        }

        .timer {
            margin-top: 5px;
            padding-top: 6px;
            padding-bottom: 6px;
            padding-left: 20px;

            p {
                font-size: 14px;
            }

            span {
                width: 40px;

                .padding-l {
                    padding-left: 10px;
                }
            }
        }
    }

    .darken-layout,
    .dark-layout {
        .footer-title {
            border-bottom: 1px solid $border-bottom-g;
        }

        .subscribe-wrapper {
            padding-bottom: 10px;
        }
    }

    .footer-title {
        margin-top: 10px;
        text-align: left;
        border-bottom: 1px solid $round-border;
        position: relative;

        &.footer-mobile-title {
            margin-top: 0;
        }

        h4 {
            text-transform: uppercase;
            font-weight: 700;
            cursor: pointer;
        }

        &.active {
            .according-menu {
                font: normal normal normal 14px/1 $font-awesome;

                &:before {
                    content: "\f106";
                    position: absolute;
                    right: 2px;
                    top: 2px;
                }
            }
        }

        .according-menu {
            font: normal normal normal 14px/1 $font-awesome;

            &:before {
                content: "\f107";
                position: absolute;
                right: 2px;
                top: 2px;
            }
        }
    }

    .banner-slider {
        .home-banner {
            >div {
                img {
                    margin-top: 30px;
                }

                &:last-child {
                    img {
                        margin-top: 30px;
                    }
                }
            }
        }
    }

    .lookbook {
        .row {
            >div {
                &:last-child {
                    .lookbook-block {
                        margin-top: 30px;
                    }
                }
            }
        }
    }

    .lookbook-section {
        .lookbook-img {
            >div {
                margin-bottom: 25px;

                +div {
                    img {
                        margin-top: 0;
                    }
                }
            }
        }

        &.lookbook {
            .row {
                >div {
                    &:last-child {
                        .lookbook-block {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }

    .full-scroll-footer {
        .sub-footer {
            p {
                padding: 0;
                line-height: 25px;
            }
        }
    }

    .box-layout-body {
        .box-layout {
            margin-top: -40px;
        }
    }

    .layout-20 {
        padding: 15px;
    }

    .home-slider {
        .slider-details {
            top: 15%;
            right: 8%;
            padding: 0px;
            width: 225px !important;
            height: 225px !important;

            h1 {
                font-size: 25px;
                margin: 5px 0;
            }

            h2 {
                font-size: 20px;
            }

            h3 {
                font-size: 18px;

                &:before,
                &:after {
                    width: 8px;
                    height: 8px;
                    top: 5px;
                }
            }

            h4 {
                font-size: 16px;
            }

            .btn-white {
                padding: 5px 10px;
                font-size: 14px;
            }
        }

        &.fullpage {
            .slick-dots {
                display: flex;
                bottom: 70px;
                top: unset;
                left: 50%;
                transform: translateX(-50%);

                li {
                    button {
                        &:before {
                            color: #0a0100;
                        }
                    }
                }
            }
        }
    }

    .footer-contant {
        text-align: left;

        .footer-logo {
            margin-top: 20px;
            margin-bottom: 20px;
        }

        li {
            text-align: left;
        }

        .footer-social {
            ul {
                margin: unset;

                li {
                    padding-right: 25px;
                    padding-left: 0;
                }
            }
        }
    }

    .social-white {
        li {
            &:first-child {
                padding-left: 0;
            }
        }
    }

    .footer-theme {
        .footer-mobile-title {
            display: block !important;
        }

        text-align: center;

        .col {
            max-width: 100%;
            flex-basis: unset;
        }

        .footer-social {
            margin-bottom: 15px;
        }

        .sub-title {
            li {
                padding-top: 7px;
            }

            .contact-list {
                i {
                    position: relative;
                    top: 0;
                    margin-right: 10px;
                }

                li {
                    padding-left: 0;
                }
            }
        }
    }

    .footer-social {
        ul {
            margin: 0 auto;
        }

        li {
            padding: 0 15px;
        }
    }

    .footer-end {
        text-align: center;
    }

    .sub-footer {
        .payment-card-bottom {
            justify-content: center;
            padding-bottom: 25px;
        }
    }

    .subscribe-wrapper {
        margin: 10px 0 0 0;
        order: 0;
    }

    .footer-theme2 {
        p {
            margin-bottom: 10px;
        }

        .footer-mobile-title {
            display: block !important;
        }

        .p-set {
            padding: 0 15px !important;
        }

        .col {
            padding: 0;
        }

        .footer-link {
            li {
                display: flex;
                padding-top: 10px;
            }

            h4 {
                padding-bottom: 10px;
            }
        }

        .contact-details {
            li {
                padding-left: 0;
                padding-top: 10px;
            }
        }

        .footer-link-b {
            li {
                display: flex;
                padding-top: 10px;
            }

            h4 {
                margin-top: 0;
                padding-bottom: 10px;
            }
        }

        &.section-light {
            .footer-block {
                h4 {
                    padding-bottom: 10px;
                }

                .subscribe-white {
                    padding: 40px 65px;

                    h2 {
                        margin-bottom: 20px;
                    }

                    .form-group {
                        display: flex;

                        .btn-solid,
                        .btn-outline {
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        .subscribe-block {
            h2 {
                margin-bottom: 20px;
            }
        }

        h4 {
            padding-bottom: 10px;
        }
    }

    .darken-layout {
        .footer-theme {
            .footer-mobile-title {
                h4 {
                    color: $white;
                }
            }
        }
    }

    .dark-layout {
        .small-section {
            padding-bottom: 40px;
        }

        section {
            padding-top: 0;
        }

        .section-b-space {
            border: none;
            padding-bottom: 0;
        }

        .subscribe-wrapper {
            order: -1;
        }

        .footer-theme2 {
            .subscribe-wrapper {
                .subscribe-block {
                    padding: 10px 60px;

                    h2 {
                        display: none;
                    }

                    .form-group {
                        display: flex;

                        .btn-solid,
                        .btn-outline {
                            margin-top: 0;
                        }
                    }
                }
            }
        }
    }

    .social-white {
        margin-bottom: 10px;
    }

    .category-border {
        div {
            .category-banner {
                .category-box {
                    h2 {
                        font-size: 25px;
                    }
                }
            }
        }
    }

    .pet-layout-footer {
        .footer-theme2 {
            .footer-link {
                .social-white {
                    margin-top: 30px;
                    margin-bottom: 20px;

                    li {
                        display: unset;
                    }
                }
            }
        }
    }

    .box-layout {
        .small-section {
            padding-top: 0;
        }
    }

    .white-layout {
        .footer-theme2 {
            >div {
                &:nth-child(2) {
                    order: -1;
                }
            }

            &.footer-border {
                padding: 0;
                border: none;
            }

            .footer-block {
                .footer-container {
                    max-width: 540px;
                    width: 100%;

                    .footer-mobile-title {
                        margin-top: 20px;
                    }
                }

                .subscribe-white {
                    h2 {
                        display: none;
                    }
                }
            }
        }
    }

    .category-bg {
        padding: 30px 40px;

        .image-block {
            &.even {
                &:after {
                    bottom: 85%;
                }
            }

            &:after {
                width: 25px;
                margin-top: -25px;
            }

            &:hover {
                &:after {
                    height: 50px;
                }
            }
        }

        .contain-block {
            h2 {
                font-size: 18px;
                margin-top: 8px;
            }

            h6 {
                letter-spacing: 0.1em;
                font-size: 12px;
            }

            &:after {
                width: 25px;
            }

            &.even {
                &:after {
                    top: 87%;
                }
            }

            .category-btn {
                margin-bottom: 10px;
                margin-top: 10px;
                letter-spacing: 0.1em;
                padding: 5px 15px;
            }

            &:after {
                bottom: 87%;
            }

            &:hover {
                &:after {
                    height: 50px;
                }
            }
        }
    }

    .layout9-box {
        .product-box {
            .img-block {
                min-height: unset;
            }
        }
    }

    .product-box,
    .product-wrap {
        .img-wrapper {
            .cart-box {
                &.style-1 {
                    padding: 7px 4px;
                    bottom: 7px;

                    i {
                        padding-left: 4px;
                        padding-right: 4px;
                    }
                }
            }
        }
    }

    .product-box,
    .product-wrap {
        .cart-detail {
            top: 5px;
            right: 10px;
        }

        &:hover {
            .product-info {
                .add-btn {
                    animation: none;
                }
            }
        }

        .img-wrapper {
            .cart-box {
                padding: 8px 10px;
                bottom: 20px;

                i {
                    font-size: 15px;
                    padding-left: 4px;
                    padding-right: 4px;
                }
            }
        }
    }

    .product-box {

        .cart-info,
        .cart-detail {
            opacity: 1;
        }

        .img-wrapper {
            .cart-box {
                opacity: 1;
            }
        }

        &:hover {
            .img-wrapper {
                .cart-box {
                    animation: none;
                }
            }

            .cart-info,
            .cart-detail {
                button {
                    animation: none;
                }

                a {
                    i {
                        animation: none;
                    }

                    &:nth-child(2) i {
                        animation: none;
                    }

                    &:nth-child(3) i {
                        animation: none;
                    }

                    &:nth-child(4) i {
                        animation: none;
                    }
                }
            }
        }
    }

    .product-box,
    .product-wrap {
        .product-info {
            .add-btn {
                opacity: 1;
            }
        }
    }

    .no-slider {
        &.five-product-row {
            .product-box {
                flex: 0 0 50%;
                max-width: calc(50% - 20px);
                margin-left: 10px;
                margin-right: 10px;
            }
        }
    }

    .theme-tab {
        .tab-title2 {
            font-size: 20px;

            li {
                padding-left: 15px;
                padding-right: 15px;
            }

            &:after {
                top: 15px;
            }
        }
    }

    .full-banner {
        padding-top: 120px;
        padding-bottom: 120px;

        &.parallax-layout {
            padding-top: 120px;
            padding-bottom: 120px;
        }

        &.feature-banner {
            .banner-decor {

                .left-img,
                .right-img {
                    display: none;
                }
            }

            h2 {
                margin-bottom: 35px;
            }
        }

        .banner-contain {
            h2 {
                font-size: 75px;
            }
        }

        .santa-img {
            img {
                width: 125px;
            }
        }
    }

    .pet-parallax {
        .full-banner {
            padding-top: 120px;
            padding-bottom: 120px;
        }
    }

    .collection-banner {
        .contain-banner {
            padding-left: 60px;
            padding-right: 60px;

            &.banner-3 {
                padding-left: 40px;
                padding-right: 40px;

                h2 {
                    font-size: 20px;
                }

                h4 {
                    font-size: 18px;
                }
            }

            &.banner-4 {
                padding-left: 45px;
                padding-right: 45px;

                h2 {
                    font-size: 18px;
                }
            }

            h2 {
                font-size: 35px;
            }
        }
    }

    .home-slider {
        .home {
            height: 60vh;
        }

        .slider-contain {
            height: 60vh;

            h1 {
                font-size: 35px;
                margin-top: 5px;
            }

            .btn-solid,
            .btn-outline {
                margin-top: 10px;
                // padding: 10px 25px; //stock
                padding: 4px 8px; // added
            }
        }

        &:hover {
            .slick-next {
                right: 80px;
            }

            .slick-prev {
                left: 80px;
            }
        }
    }

    .upper-canvas-section {
        &.height-100 {
            .home-slider {
                .slider-contain {
                    &.left-canvas-section {
                        height: auto;
                        padding-top: 120px;
                    }

                    &.right-canvas-section {
                        height: auto;
                        padding-top: 0;
                        padding-bottom: 50px;
                    }
                }
            }
        }
    }

    .lower-canvas-section {
        .left-canvas-section {
            padding: 20px 0 40px;
        }
    }

    .canvas-bg {
        background-position: right !important;
    }

    .home-form {
        .table-form {
            .form-group {
                margin-bottom: 20px;
            }

            .btn {
                background-size: 950px;
            }
        }
    }

    .height-85 {
        .home-slider {
            .home {
                height: 65vh;
            }

            .slider-contain {
                height: 65vh;
            }
        }
    }

    .background {
        .col {
            flex-basis: unset;
        }

        .contain-bg {
            margin: 10px 0;
        }
    }

    .full-box {
        .center-slider {
            .offer-slider {
                text-align: center;

                .product-box {
                    border: none;
                    display: inline-block;

                    .img-wrapper {
                        text-align: center;

                        img {
                            height: 200px;
                        }
                    }

                    &:hover {
                        box-shadow: none;
                    }
                }
            }
        }

        .theme-card {
            .offer-slider {
                .sec-1 {
                    display: flex;

                    .product-box2 {
                        .media {
                            .media-body {
                                .color-variant {
                                    li {
                                        height: 18px;
                                        width: 18px;
                                        padding-right: 3px;
                                    }
                                }
                            }
                        }

                        +.product-box2 {
                            border-top: none;
                        }
                    }
                }
            }
        }
    }

    .theme-tab {

        .tab-title,
        .tab-title2 {
            li {
                padding-left: 5px;
                padding-right: 5px;
            }
        }

        .tab-content {
            .product-tab {
                .tab-box {
                    .product-box2 {
                        img {
                            height: 150px;
                            padding: 5px;
                        }
                    }
                }
            }
        }
    }

    .theme-modal {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    .modal-bg {
                        .age-content {
                            h2 {
                                margin-bottom: 20px;
                                padding-bottom: 20px;
                            }

                            h4 {
                                margin-bottom: 20px;
                                font-size: 15px;
                            }
                        }
                    }
                }
            }
        }
    }

    .filter-main-btn {
        span {
            font-size: 16px;
        }
    }

    .tap-top {
        bottom: 70px;
        right: 20px;
    }

    .footer-theme2 {

        .footer-link,
        .footer-link-b {
            li {
                padding-left: 0;
            }
        }
    }

    .product-christmas {
        .product-box {
            .cart-wrap {
                background-color: var(--theme-color);
                bottom: 0;
                opacity: 1;
            }
        }
    }

    .bake-header {
        .absolute-logo {
            left: 50px;
            transform: unset;
        }
    }

    .bakery-banner {
        .home-slider {
            .slider-contain {
                >div {
                    padding: 40px 40px;
                }

                h4 {
                    letter-spacing: 0.2em;
                    line-height: 22px;
                }
            }
        }
    }

    .category-bake {
        .category-box {
            padding: 10px 0;

            h3 {
                font-size: 18px;
            }
        }
    }

    .custom-cake {
        .steps-media {
            margin-bottom: 20px;
        }

        .customized-bottom {
            display: block;
            text-align: center;

            .form-group {
                margin-right: 0;
                width: 100%;
                margin-bottom: 20px;
                text-align: left;
            }
        }
    }

    .cycle-box-row {
        >div {
            flex: 0 0 100%;
            max-width: 100%;
        }
    }

    .bg-dark-bottom {
        padding: 14px !important;
    }

    .home-slider {
        .banner-content-bottom {
            .slick-slider {
                margin-top: 0;
                padding: 50px 0 100px;
            }

            .center-cls {
                .slick-slider {
                    margin-top: 14px;
                }
            }
        }
    }

    .height-100 {
        &.sm-responsive {
            .home-slider {
                .home {
                    height: 80vh;
                }

                .slider-contain {
                    height: calc(80vh + 80px);
                }
            }
        }

        &.xs-responsive {
            .home-slider {
                .home {
                    height: 60vh;
                }

                .slider-contain {
                    height: calc(60vh + 80px);
                }
            }
        }
    }

    .single-detail-section {
        .row {
            >div {
                &:first-child {
                    margin-bottom: 20px;
                }
            }
        }
    }

    .pt-res-0 {
        padding-top: 0;
    }

    .category-img-wrapper {
        .row {
            margin-bottom: -30px;

            >div {
                margin-bottom: 30px;
            }
        }
    }

    .banner-style-1 {
        .full-box {
            .card-margin {
                margin-bottom: 20px;
            }

            .theme-card {
                .offer-slider {
                    .product-box2 {
                        width: 100%;
                        padding: 20px;

                        .media {
                            img.h-cls {
                                height: 200px;
                            }
                        }
                    }
                }
            }
        }
    }

    .banner-style-2 {
        &.absolute-banner {
            .absolute-bg {
                .collection-banner {
                    .absolute-img {
                        img {
                            left: 60px;
                            top: 30px;
                        }
                    }
                }
            }
        }
    }

    .service-style-border {
        .service-block {
            justify-content: center;

            .media-body {
                padding-left: 0;
                border-left: none;
            }

            svg {
                margin-right: 0;
            }
        }
    }

    .sale-box {
        left: -8px;

        .heading-right {
            h3 {
                transform: translate(50%, -50%) rotate(-90deg) scale(0.7);
            }
        }
    }

    // inner pages //
    .blog-detail-page .comment-section li {
        padding-top: 40px;
        padding-bottom: 40px;
    }

    .product-pagination {
        .pagination {
            justify-content: center;
            border-left: 1px solid $round-border;
        }
    }

    .testimonial {
        background-color: $grey-about;

        .testimonial-slider {
            .media {
                .media-body {
                    padding: 30px 30px 30px 30px;
                }
            }
        }
    }

    .about-page {
        text-align: center;

        p {
            line-height: 1.5;
        }
    }

    .team {
        h2 {
            font-size: 25px;
        }
    }

    .layout2-logo {
        padding-right: 70px;
    }

    .faq-section {
        .theme-accordion {
            .card {
                .card-body {
                    p {
                        line-height: 1.5 !important;
                    }
                }

                .card-header {
                    button {
                        font-size: 14px !important;
                        white-space: normal;
                        width: 100%;
                        padding: 16px 30px 16px 18px !important;
                    }
                }
            }
        }
    }

    .product-wrapper-grid {
        &.list-view {
            .product-box {
                display: block;
                border: 1px solid #eee;
                padding: 15px;

                .img-wrapper,
                .img-block {
                    margin: 0 auto 15px;
                }

                .product-detail {
                    padding-left: 0;
                }
            }
        }
    }

    .register-page {
        .theme-card {
            padding: 25px;

            .theme-form {
                input {
                    margin-bottom: 25px;
                    padding: 13px 18px;
                }
            }
        }
    }

    .collection {
        .partition-collection {
            >div {

                &:nth-last-child(1),
                &:nth-last-child(2),
                &:nth-last-child(3) {
                    margin-top: 30px;
                }
            }
        }
    }

    .collection-product-wrapper {
        .product-pagination {
            .pagination {
                .page-item {
                    a {
                        padding: 15px 18px;
                    }
                }
            }

            .product-search-count-bottom {
                justify-content: center;
                padding-right: 0;

                h5 {
                    padding: 10px 0;
                }
            }

            .theme-paggination-block {
                nav {
                    border-right: 1px solid #ddd;
                    border-bottom: 1px solid #ddd;
                }
            }
        }
    }

    .cart-section,
    .wishlist-section {
        tbody {
            tr {
                td {
                    a {
                        justify-content: center;
                        display: flex;
                        align-items: center;
                    }
                }
            }
        }
    }

    .cart-section {
        .cart-table {
            thead {
                th {
                    &:nth-last-child(-n + 4) {
                        display: none;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    &:nth-last-child(-n + 4) {
                        display: none;
                    }

                    .mobile-cart-content {
                        display: flex;
                    }
                }
            }
        }
    }

    .wishlist-section {
        .cart-table {
            thead {
                th {
                    &:nth-last-child(-n + 3) {
                        display: none;
                    }
                }
            }
        }

        tbody {
            tr {
                td {
                    &:nth-last-child(-n + 3) {
                        display: none;
                    }

                    .mobile-cart-content {
                        display: flex;
                    }
                }
            }
        }
    }

    .blog-page {
        .order-sec {
            order: -1;
        }

        .blog-media {
            &:last-child {
                margin-bottom: 30px;
            }
        }

        .blog-sidebar {
            ul {
                li {
                    display: block;
                }
            }
        }
    }

    .tab-pane {
        iframe {
            width: 100%;
        }
    }

    .product-order {
        .product-order-detail {
            img {
                height: auto;
            }
        }
    }

    .typography_section {
        .typography-box {
            .typo-content {
                &.product-pagination {
                    .pagination {
                        border-left: none;
                    }
                }

                &.typo-buttons {
                    a {
                        &:last-child {
                            margin-top: 5px;
                        }
                    }
                }
            }
        }
    }

    .loader_skeleton {
        .collection-banner {
            .row {
                >div {
                    margin-top: 30px;

                    &:first-child {
                        margin-top: 0;
                    }
                }
            }
        }

        .banner-slider {
            .home-banner {
                >div {
                    &:first-child {
                        .home-slider {
                            margin-top: 25px;
                        }
                    }
                }
            }
        }

        .category-ldr {
            .row {
                .category-block {
                    flex: 0 0 33.33%;
                    max-width: 33.33%;
                }
            }
        }

        .box-layout {
            .small-section {
                padding-top: 20px;
            }
        }

        .center-slider {
            .product-box {
                display: flex;
                align-items: center;
                margin-top: 25px;
                margin-bottom: 25px;

                .img-wrapper {
                    height: calc(50px + (250 - 50) * ((100vw - 320px) / (1920 - 320)));
                    width: calc(120px + (230 - 150) * ((100vw - 320px) / (1920 - 320)));
                    margin-right: 15px;
                }

                .product-detail {
                    align-self: center !important;

                    h4,
                    h6 {
                        margin-left: unset;
                        margin-right: unset;
                    }
                }
            }
        }

        .service-block {

            h4,
            p {
                margin-left: auto;
                margin-right: auto;
            }
        }
    }

    .dashboard-section {
        .counter-section {
            .row {
                >div {
                    margin-bottom: 20px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .apexcharts-canvas {
            margin: 0 auto;
        }

        .dashboard-table {
            .table {
                &.product-table {

                    th,
                    td {

                        &:nth-child(2),
                        &:nth-child(3) {
                            min-width: 200px;
                        }
                    }
                }

                &.order-table-vendor {

                    td,
                    th {
                        &:nth-child(2) {
                            min-width: 200px;
                        }
                    }
                }
            }
        }
    }

    .dark-box {
        margin-right: -10px;
        margin-left: -10px;

        >div {
            max-width: 50% !important;
            flex: 0 0 50% !important;
            padding: 0 10px;
            margin-bottom: 30px;
        }

        &.partition-five {
            >div {
                &:nth-child(-n + 4) {
                    margin-bottom: 30px;
                }
            }
        }

        .product-box {
            margin-bottom: 0;
            padding: 0;
            border: none;

            .cart-detail {
                i {
                    padding: 4px;
                    font-size: 10px;
                    margin-bottom: 7px;
                }
            }

            .product-info {
                padding-bottom: 0;

                .add-btn {
                    display: block;
                }
            }

            &:hover {
                border: none;
            }
        }

        &.four-product {
            >div {
                max-width: 50% !important;
                flex: 0 0 50% !important;
            }
        }
    }

    .tracking-page {
        .wrapper {
            margin: 20px 0 5px;

            .arrow-steps {
                .step {
                    min-width: 130px;
                    display: block;
                    width: 100%;
                    margin-bottom: 26px;

                    &:before {
                        display: none;
                    }

                    &:after {
                        transform: rotate(90deg) translateY(-50%);
                        top: 30px;
                        right: 50%;
                        border-top: 17px solid transparent;
                        border-bottom: 21px solid transparent;
                        border-left: 17px solid $top-header;
                    }

                    &:last-child {
                        &:after {
                            display: none;
                        }
                    }
                }
            }
        }

        .tracking-detail {
            ul {
                li {
                    .left {
                        min-width: 200px;
                    }
                }
            }
        }
    }

    .sticky-bottom-cart {
        .selection-section {
            .form-group {
                width: 120px;

                &:nth-child(2) {
                    margin-left: 10px;
                }
            }
        }
    }

    .mordern-box {
        margin-top: 50px;

        .absolute-img {
            img {
                width: 50%;
                margin-left: 20px;
                margin-top: -76px;
            }
        }

        .mordern-content {
            p {
                display: none;
            }
        }
    }

    .sitemap_page {
        .row {
            >div {
                padding-top: 0;
                padding-bottom: 0;

                &:nth-child(odd) {
                    background-color: transparent;
                }
            }
        }
    }

    .blog-page {
        .blog-sidebar {
            .theme-card {
                background-color: transparent;
                padding: 0;
            }
        }
    }

    .setting-box {
        width: 460px;
    }

    .dashboard-section {
        .dashboard-table {
            .table {
                &.order-table {

                    th,
                    td {
                        &:nth-child(2) {
                            min-width: 80px;
                        }

                        &:nth-child(3) {
                            min-width: 180px;
                        }
                    }
                }
            }

            .table {
                &.wishlist-table {

                    th,
                    td {
                        &:nth-child(2) {
                            min-width: 80px;
                        }

                        &:nth-child(3) {
                            min-width: 180px;
                        }

                        &:nth-child(5) {
                            min-width: 150px;
                        }
                    }
                }
            }
        }
    }

    .theme-invoice-1 {
        .invoice-header {
            .detail-bottom {
                ul {
                    display: block;
                }
            }
        }
    }

    .invoice-wrapper {
        .authorise-sign {
            width: 20%;

            h6 {
                font-size: 12px;
                padding-top: 6px;
                margin-top: 10px;
            }
        }
    }

    .theme-invoice-2 {
        .invoice-header {
            .top-bar {
                display: block;
            }
        }

        .invoice-body {
            .date-detail {
                display: block;

                li {
                    width: 32%;

                    &:first-child {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }

    .theme-invoice-3 {
        .invoice-header {
            ul {
                margin-bottom: -18px;

                li {
                    margin-bottom: 18px;

                    &:first-child {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }

    .theme-invoice-4 {
        .title-sec {
            .title {
                position: relative;
                text-align: center;
                margin-top: 20px;
            }
        }

        .invoice-wrapper {
            .invoice-footer {
                ul {
                    display: block;
                    margin-bottom: -20px;

                    li {
                        margin-bottom: 20px;
                    }
                }
            }
        }
    }

    .theme-invoice-5 {
        .header-panel {
            ul {
                margin-bottom: -20px;

                li {
                    margin-bottom: 20px;

                    &:first-child {
                        display: block;
                        width: 100%;
                    }
                }
            }
        }
    }
}

@media (max-width: 577px) {
    .header-style-1 {
        .main-menu {
            z-index: 9;
        }

        .toggle-nav {
            z-index: 10;
        }

        .top-header {
            .header-dropdown {
                li {
                    padding: 0;

                    i {
                        color: #999999;
                    }
                }
            }
        }

        .brand-logo {
            position: relative;
            left: 0;
            padding-top: 15px !important;
            padding-bottom: 15px !important;
            transform: none;

            img {
                width: 80%;
            }
        }

        .toggle-nav {
            top: -39px;
        }

        .btn-solid.btn-xs {
            padding: 2px 11px;
        }
    }

    .header-style-2 {
        .main-menu {
            .brand-logo {
                position: relative;
                padding-left: 40px;
            }
        }

        .main-nav-center {
            .toggle-nav {
                left: 15px;
            }
        }
    }

    .header-style-5 {
        &.border-style {
            .main-menu {
                z-index: 9;

                .menu-right {
                    .icon-nav {
                        li {
                            padding-top: 0;
                            padding-bottom: 0;
                        }
                    }
                }
            }

            .main-nav-center {
                .toggle-nav {
                    right: 15px;
                    top: -78px;
                }
            }
        }

        &.color-style {
            .top-header {
                .header-dropdown {
                    >li {
                        padding: 0;

                        img {
                            opacity: 0.6;
                            width: 22px;
                            filter: invert(0.7);
                        }
                    }
                }
            }

            &.style-classic {
                .main-menu {
                    .menu-right {
                        .icon-nav {
                            li {
                                padding: 0;
                            }
                        }
                    }
                }

                .top-header {
                    .header-dropdown {
                        >li {
                            padding: 0;
                        }
                    }
                }
            }
        }
    }

    .header-style-7 {
        .main-menu {
            .menu-left {
                .navbar {
                    padding: 25px 15px 25px 0 !important;
                    .bar-style {
                        width: 20px;
                    }
                }
            }
            .menu-right {
                .icon-nav {
                    li {
                        padding-right: 10px;
                    }
                    .mobile-cart {
                        padding-right: 0;
                        .show-div {
                            &.shopping-cart {
                                top: 52px;
                                bottom: unset;
                                left: unset !important;
                                li {
                                    .media {
                                        img {
                                            width: auto;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    .mobile-setting,.mobile-cart, .mobile-search {
                        position: relative;
                        bottom: 0;
                        right: 0 !important;
                        img {
                            display: block;
                            width: 25px;
                        }
                    }
                    .mobile-setting {
                        img {
                            width: 27px;
                        }
                        .setting {
                            top: 52px;
                            bottom: unset;
                            left: unset !important;
                        }
                    }
                    .delivery-area {
                        padding-left: 0;
                        img {
                            width: 20px;
                        }
                    }
                }
            }
            .brand-logo {
                position: relative;
                left: 0;
                margin-right: 0;
                transform: none;
                img {
                    width: 70%;
                    height: auto;
                }
            }
        }
        .cart_qty_cls {
            padding: 1px;
            width: 15px;
            height: 15px;
        }
    }

    .shop-sidebar-demo {
        .shop-main {
            margin-top: 74px;
        }
    }

    .setting-box {
        width: 360px;
    }

    .top-header {
        &.top-header-theme {
            .header-dropdown {
                li {

                    i,
                    a {
                        color: #999999;
                    }
                }
            }
        }
    }

    .margin-default {
        margin-bottom: -15px;

        >div {
            margin-bottom: 15px;
        }
    }

    .cart_qty_cls {
        top: -10px;
    }

    header {
        &.sticky {
            .top-header {
                display: block;
            }
        }

        &.header-5 {
            &.left-sidebar-header {
                .top-header {
                    .header-dropdown {

                        .mobile-wishlist,
                        .mobile-account {
                            img {
                                filter: invert(1);
                                opacity: 0.8;
                            }
                        }
                    }
                }
            }
        }
    }

    .gift-card-section {
        margin: 0 15px;
        .container {
            padding: 0;
        }
    }

    .deal-product-space {
        padding-top: 155px;
        padding-bottom: 85px !important;
    }

    .top-panel-adv {
        display: none;
    }

    .header-compact {
        .main-menu {
            .menu-right {
                .icon-nav {
                    .mobile-setting, .mobile-cart, .mobile-search {
                        z-index: 9;
                    }
                }
            }
        }
        .main-menu {
            .brand-logo {
                position: relative;
                left: 0;
                margin-right: 0;
                transform: none;
                padding-top: 20px !important;
                padding-bottom: 20px !important;
            }
            .menu-right {
                .icon-nav {
                    .mobile-search {
                        z-index: 10;
                    }
                }
            }
        } 
        .main-nav-center {
            .toggle-nav {
                top: -72px;
            }
        }
        .top-header {
            .header-dropdown {
                li {
                    padding: 0;
                }
            }
        }
    }

    .small-slider {
        &.home-fashion {
            .home-slider {
                .home {
                    height: 35vh;

                    .slider-contain {
                        height: 35vh;
                    }
                }
            }
        }
    }

    .container-fluid {
        &.custom-container {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .detail-cannabis {
        .detail_section {
            >div {
                padding: 30px 25px;
            }
        }
    }

    #fb-root {
        display: none;
    }

    .section-b-space {
        padding-bottom: 30px;
    }

    section,
    .section-t-space {
        padding-top: 30px;
    }

    header {
        &.left-header {
            .top-header {
                height: 70px;

                .header-dropdown {
                    li {
                        padding: 0;
                    }

                    .mobile-wishlist,
                    .mobile-account {
                        img {
                            display: none;
                        }

                        i {
                            display: block;
                        }
                    }
                }
            }

            .main-menu {
                .menu-left {
                    .mobile-logo {
                        position: absolute;
                        padding: 0 !important;
                        transform: unset;
                        right: unset;
                        left: 15px;
                        margin-right: 0;
                        top: 16px;
                    }

                    .navbar {
                        position: absolute;
                        right: 15px;
                        padding: 0 !important;
                        top: 22px;
                    }
                }
            }
        }

        &.video-header {
            .top-header {
                .header-dropdown {
                    li {
                        padding: 0;
                    }
                }
            }
        }

        &.header-tools {
            .top-header {
                .header-dropdown {
                    li {
                        padding: 0;
                    }
                }
            }
        }

        &.header-style {
            .brand-logo {
                left: 0;
                margin-right: 0;
                transform: none;
            }

            .top-header {
                .header-dropdown {
                    li {
                        img {
                            filter: invert(0.55);
                        }
                    }
                }
            }
        }

        &.header-5 {
            &.left-sidebar-header {
                .main-menu {
                    .menu-left {
                        .navbar {
                            position: absolute;
                            right: 0px;
                            padding: 0 !important;
                        }
                    }
                }

                .brand-logo {
                    position: relative;
                }

                .container-fluid {
                    padding-left: 15px;
                    padding-right: 15px;
                }
            }
        }
    }

    .demo-right {
        a {
            right: 14px;
            width: 30px;
            height: 44px;
            line-height: 15px;
            letter-spacing: 2px;
            font-size: 12px;
            padding: 8px 4px 10px;
        }
    }

    .offer-box {
        display: none;
    }

    .advertise-section {
        .full-banner {
            padding-top: 50px;
            padding-bottom: 50px;

            .banner-contain {
                padding: 30px 10px;

                h2 {
                    font-size: 40px;
                    margin-bottom: 0;
                    margin-top: -7px;
                }

                h3 {
                    font-size: 20px;
                    margin-bottom: 3px;
                }

                h4 {
                    font-size: 16px;
                    letter-spacing: 0.03em;
                }
            }
        }
    }

    .setting-sidebar {
        width: 35px;
        height: 35px;

        i {
            font-size: 19px;
        }
    }

    .dark-light {
        width: 35px;
        height: 35px;
    }

    .portfolio-padding {
        padding-bottom: 15px;
    }

    .portfolio-section {
        .isotopeSelector {
            margin-bottom: 15px;
        }
    }

    .portfolio-section {
        .filter-button {
            padding: 0 15px;
            font-size: 16px;
            line-height: 30px;
        }
    }

    .service_slide {
        .home-slider {
            .slider-contain {
                height: 58vh;
            }
        }
    }

    .portfolio-section {
        &.metro-section {
            .product-box {
                .cart-wrap {
                    a {
                        i {
                            font-size: 18px;
                            padding: 7px;
                        }
                    }

                    button {
                        i {
                            font-size: 18px;
                            padding: 7px;
                        }
                    }
                }
            }
        }
    }

    .filter-section {
        .filter-container {
            ul {
                &.filter {
                    >li {
                        padding: 0 10px;

                        >a {
                            font-size: 16px;
                            line-height: 30px;
                        }
                    }
                }
            }
        }
    }

    .tab-left {
        .theme-tab {
            .left-side {
                .left-tab-title {
                    margin-bottom: 15px;
                }
            }
        }
    }

    h2 {
        font-size: 24px;
    }

    .tools-service {
        &.absolute-banner {
            .absolute-bg {
                padding-bottom: 20px;
                box-shadow: 0 0 8px 0 #ddd;
            }
        }

        .service-block {
            +.service-block {
                margin-top: 20px;
            }
        }

        .service-block,
        .service-block1 {
            svg {
                margin-bottom: 7px;
            }
        }
    }

    .top-header {
        .header-dropdown {
            li {
                i {
                    padding-right: 0;
                    font-size: 18px;
                }
            }
        }
    }

    .tools-parallax-product {
        &.full-banner {
            padding-top: 45px;
            padding-bottom: 45px;
        }
    }

    header {
        &.header-gym {
            position: relative;
            background-color: $black;

            .main-menu {
                padding: 0;
                z-index: 9;

                .brand-logo {
                    left: 0;
                    transform: unset;
                }
            }

            .top-header {
                .header-dropdown {
                    li {
                        a {
                            i {
                                padding-right: 0;
                            }
                        }
                    }
                }
            }
        }

        &.header-5 {
            .top-header {
                .header-dropdown {
                    .onhover-dropdown {
                        .onhover-show-div {
                            top: unset;
                        }
                    }
                }
            }
        }

        &.header-6 {
            .main-menu {
                .brand-logo {
                    position: relative;
                    left: 0;
                    transform: none;
                }
            }
        }

        &.header-7 {
            position: relative;
            z-index: unset;
            background-color: $font-color;

            .main-menu {
                .menu-left {
                    .navbar {
                        padding: 15px 45px 15px 0 !important;
                    }
                }
            }

            .main-nav-center {
                .toggle-nav {
                    top: -70px;
                }
            }

            .game-layout {
                background-color: $font-color;
            }
        }

        &.green-gradient {
            .top-header {
                .header-dropdown {
                    li {
                        padding: 0;
                    }
                }
            }
        }

        &.header-christmas {
            .main-menu {
                .brand-logo {
                    position: relative;
                }
            }
        }

        &.header-metro {
            .metro {
                z-index: 10;
            }

            .top-header {
                z-index: 9;
            }
        }
    }

    .header-style-1 {
        .main-menu {
            .menu-right {
                .icon-nav {
                    .onhover-div {
                        .show-div {
                            top: unset;
                        }
                    }
                }
            }
        }
    }

    .gym-banner {
        .collection-banner {
            .contain-banner {
                padding-left: 15px;
                padding-right: 15px;

                h2 {
                    font-size: 25px;
                    margin-bottom: -3px;
                }
            }
        }
    }

    .banner-furniture {
        &.gym-banner {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .gym-product {
        .part-cls {
            margin-top: -20px;

            >div {
                &:nth-last-child(-n + 3) {
                    .product-box {
                        margin-top: 20px;
                    }
                }
            }
        }

        .partition-cls,
        .part-cls {
            margin-left: -10px;
            margin-right: -10px;

            >div {
                padding-left: 10px;
                padding-right: 10px;

                .product-box {
                    margin-top: 20px;
                }
            }
        }

        .product-box {
            &:hover {
                transform: unset;
            }
        }
    }

    .header-metro {
        .layout3-menu {
            .main-menu {
                .menu-left {
                    .main-menu-right {
                        .toggle-nav {
                            right: 15px;
                        }
                    }
                }
            }
        }
    }

    .layout3-menu {
        .main-menu {
            .menu-left {
                width: calc(100vw - 32px);

                .main-menu-right {
                    .toggle-nav {
                        right: 0;

                        .sidebar-name {
                            display: none;
                        }
                    }
                }
            }

            >div {
                &:nth-child(2) {
                    position: absolute;
                    left: 54%;
                    right: 50%;
                    transform: translate(-50%, 0);
                    top: 0;
                }

                &:nth-child(3) {
                    height: 0;
                }
            }
        }

        .brand-logo {
            left: 35%;
        }
    }

    .search-overlay {
        >div {
            .closebtn {
                top: 15px;
                right: 30px;
            }
        }
    }

    .btn-solid,
    .btn-outline {
        padding: 7px 15px;
    }

    .product-box,
    .product-wrap {

        .product-detail,
        .product-info {
            .color-variant {
                padding-top: 10px;
            }
        }
    }

    .detail-inline {
        display: block;

        h4 {
            margin-left: unset;
            margin: 2px 0 6px !important;
        }
    }

    .product-para {
        p {
            padding-bottom: 10px;
        }
    }

    .category-bg-section {
        .category-border-2 {
            .border-padding {
                &:nth-last-child(-n + 2) {
                    padding-top: 10px;
                }
            }
        }
    }

    footer {
        margin-bottom: 60px;

        .btn-solid {
            padding: 13px 29px;
        }

        p {
            line-height: 20px;
        }

        &.footer-black {
            .below-section {
                padding-top: 75px;
            }

            &.footer-light {
                .subscribe {
                    h4 {
                        margin-bottom: 0;
                    }
                }
            }
        }
    }

    .sidebar-shop-footer {
        margin-bottom: 0;
    }

    .partition1 {
        margin-bottom: -15px;

        >div {
            margin-bottom: 15px;
        }
    }

    .theme-modal {
        .modal-dialog {
            .quick-view-modal {
                .product-right {
                    margin: 15px 0 10px 0;

                    h2 {
                        font-size: 18px;
                        margin-bottom: 10px;
                    }

                    h3 {
                        font-size: 22px;
                        margin-bottom: 10px;
                    }

                    .color-variant {
                        li {
                            height: 25px;
                            width: 25px;
                        }
                    }

                    .border-product {
                        padding-top: 10px;
                        padding-bottom: 15px;
                    }

                    .size-box {
                        margin-top: 5px;
                        margin-bottom: 5px;
                    }

                    .product-description {
                        .qty-box {
                            margin-top: 5px;
                        }
                    }
                }
            }
        }

        &.cart-modal {
            .modal-dialog {
                .modal-content {
                    .modal-body {
                        .modal-bg {
                            &.addtocart {
                                .media {
                                    a {
                                        img {
                                            max-width: 105px;
                                            margin-bottom: 10px;
                                        }
                                    }

                                    .media-body {
                                        .buttons {
                                            a {
                                                padding: 4px 5px;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        &.exit-modal {
            .media {
                align-items: center;

                img {
                    width: 25%;
                }

                .media-body {
                    h4 {
                        font-size: 16px;
                        margin-bottom: 0;
                    }

                    h2 {
                        margin-bottom: 2px;
                    }

                    h5 {
                        font-size: 16px;
                        line-height: 18px;
                    }
                }
            }

            .modal-dialog {
                .modal-content {
                    .modal-body {
                        .modal-bg {
                            padding: 12px;
                        }
                    }
                }
            }
        }
    }

    .dark-layout {
        .footer-theme2 {
            .col {
                padding: 0 15px;
            }

            .subscribe-wrapper {
                .subscribe-block {
                    padding: 10px 20px 20px 10px;
                }
            }
        }
    }

    .white-layout {
        .small-section {
            .footer-theme2 {
                .col {
                    padding: 0 15px;
                }
            }
        }
    }

    .footer-theme2 {
        .col {
            padding: 0 15px;
        }

        .form-p {
            padding: 0;
        }

        &.footer-border {
            padding: 15px 0;
        }

        .subscribe-block {
            padding: 0 75px;
        }

        .footer-link {
            h4 {
                padding-bottom: 10px;
            }

            li {
                padding-right: 20px;
            }
        }

        .footer-link-b {
            h4 {
                padding-bottom: 15px;
            }

            li {
                padding-right: 20px;
            }
        }
    }

    footer {
        .dark-subscribe {
            .subscribe {
                display: block;

                h4 {
                    margin-bottom: 10px;
                }

                .subscribe-form {
                    display: block;

                    .form-control {
                        margin: 0 auto 10px;
                        padding: 8px 18px;
                        border-radius: 20px;
                    }

                    .btn-solid {
                        border-radius: 25px;
                        padding: 7px 19px;
                    }
                }
            }
        }
    }

    .social-white {
        margin-top: 25px;
    }

    .category-border {
        div {
            .category-banner {
                .category-box {
                    h2 {
                        font-size: 30px;
                        padding: 10px 20px;
                    }
                }
            }
        }
    }

    .filter-main-btn {
        span {
            font-size: 14px;
        }
    }

    .category-bg {
        .image-block {
            &:after {
                display: none;
            }
        }

        .contain-block {
            &:after {
                display: none;
            }
        }

        .order-section {
            div {
                order: 1;

                &:nth-child(2) {
                    order: 4;
                    margin-bottom: 30px;
                }

                &:nth-child(3) {
                    order: 3;
                }

                &:nth-child(4) {
                    order: 2;
                    margin-bottom: 30px;
                }

                &:nth-child(5) {
                    order: 5;
                }

                &:nth-child(6) {
                    order: 6;
                }
            }
        }

        .contain-block {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }

    .theme-tab {
        .tab-title {
            margin-bottom: 25px;
        }
    }

    .full-banner {
        padding-top: 50px;
        padding-bottom: 50px;

        &.parallax-layout {
            padding-top: 50px;
            padding-bottom: 50px;
        }

        .banner-contain {
            background-color: rgba($white, 0.48);
            padding: 25px;

            .btn-solid,
            .btn-outline {
                padding: 10px 20px;
            }
        }

        &.p-left,
        &.p-right {
            .banner-contain {
                float: none;
            }
        }

        .banner-contain {
            h2 {
                font-size: 65px;
            }

            h3 {
                font-size: 40px;
            }

            h4 {
                font-size: 18px;
                padding-top: 0;
                letter-spacing: 0.3em;
            }
        }

        &.feature-banner {
            .feature-object {
                text-align: left;
                margin-left: 0;
                margin-bottom: 30px;

                li {
                    .media {
                        .media-body {
                            text-align: left;
                        }

                        img {
                            order: -1;
                            margin-right: 15px;
                            margin-left: 0;
                        }
                    }
                }
            }

            .feature-object,
            .feature-object-right {
                li {
                    margin-bottom: 30px;
                }
            }
        }
    }

    .pet-parallax {
        .full-banner {
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }

    .home-slider {
        .home {
            // height: 58vh;
            height: 32vh;
        }

        .slider-contain {
            // height: 58vh;
            height: 32vh;

            h1 {
                font-size: 30px;
            }
        }

        .slider-details {
            top: 18%;
            right: 8%;
            width: 180px !important;
            height: 180px !important;

            h1 {
                font-size: 22px;
                margin: 5px 0;
            }

            h2 {
                font-size: 18px;
            }

            h3 {
                font-size: 16px;
            }

            h4 {
                display: none;
            }

            .btn-white {
                padding: 4px 8px;
            }
        }

        &:hover {
            .slick-next {
                right: 70px;
            }

            .slick-prev {
                left: 70px;
            }

            .slick-prev,
            .slick-next {
                transform: scale(1.5);
            }
        }

        &.fullpage {
            .slick-dots {
                display: flex;
                bottom: 60px;
                top: unset;
                left: 50%;
                transform: translateX(-50%);
            }

            .home {
                .slider-contain {
                    text-align: center;
                    justify-content: center;
                    padding-left: 0;

                    >div {
                        background-color: rgba(0, 0, 0, 0.15);
                        padding: 30px 50px;
                    }

                    h1,
                    p {
                        color: $white;
                    }

                    h1 {
                        font-size: 22px;
                    }

                    p {
                        max-width: 330px;
                        font-size: 12px;
                    }

                    .btn-solid {
                        padding: 10px 15px;
                    }
                }
            }
        }
    }

    .height-85 {
        .home-slider {
            .home {
                height: 60vh;
            }

            .slider-contain {
                height: 60vh;
            }
        }
    }

    .background {
        padding: 10px 10px;

        .contain-bg {
            padding-top: 10px;
            padding-bottom: 10px;
            margin: 10px 0;

            &:hover {
                background-color: unset;
            }
        }
    }

    .category-border {
        padding: 10px 10px;

        .border-padding {
            padding: 10px 10px;
        }
    }

    .theme-tab {
        .tab-content {
            .product-tab {
                .tab-box {
                    flex: 0 0 100%;
                    max-width: calc(100% - 10px);

                    &:nth-last-child(-n + 2) {
                        margin: 0 5px 10px;
                    }

                    &:nth-last-child(-n + 1) {
                        margin: 0 5px 0;
                    }

                    .product-box2 {
                        img {
                            height: 150px;
                        }

                        .media {
                            .media-body {
                                a {
                                    h6 {
                                        margin-right: 10px;
                                    }
                                }

                                .color-variant {
                                    li {
                                        margin-top: 7px;
                                        height: 18px;
                                        width: 18px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .alert {
        right: 0 !important;
        left: 0;
        max-width: 90% !important;
    }

    .tap-top {
        width: 35px;
        height: 35px;
        padding: 2px;
        font-size: 19px;
    }

    .layout2-logo {
        padding-right: 0;
    }

    .absolute-banner {
        margin-top: 0;

        .absolute-bg {
            box-shadow: none;
            padding-bottom: 0;
        }

        &.banner-padding {
            padding-top: 0;
        }

        &.search-section {
            .absolute-bg {
                background-color: transparent;
                padding: 0 15px;
            }
        }
    }

    .box-layout-body {
        .box-layout {
            margin-top: 0;
        }
    }

    .full-scroll-menu {
        .container-fluid {
            padding-left: 15px;
            padding-right: 15px;
        }
    }

    .layout-8 {
        padding: 0 15px;
    }

    .layout-20 {
        padding: 0;
    }

    .addcart_btm_popup {
        display: none !important;
    }

    .color-picker {
        a {
            &.handle {
                width: 30px;
                height: 35px;

                i {
                    margin-left: 7px;
                    font-size: 17px;
                }
            }
        }
    }

    .full-scroll-footer {
        display: none;
    }

    .bake-header {
        .layout3-menu {
            .main-menu {
                .menu-left {
                    .main-menu-right {
                        .toggle-nav {
                            right: unset;
                            position: relative;
                        }
                    }
                }
            }
        }

        &.header-5 {
            .main-menu {
                .brand-logo {
                    left: 50%;
                    transform: translateX(-50%);
                }
            }
        }
    }

    .bakery-banner {
        .home-slider {
            .slider-contain {
                >div {
                    &:after {
                        width: calc(100% + 20px);
                        height: calc(100% + 20px);
                    }
                }
            }
        }
    }

    .category-bake {
        margin-top: 30px;

        .row {
            margin-bottom: -30px;

            >div {
                margin-bottom: 30px;
            }
        }
    }

    .bakery-box {
        .product-box {
            .add-button {
                font-size: 15px;
                padding: 5px;
                bottom: 0;
                width: 100%;
                position: relative;
            }

            .cart-info {
                a {
                    i {
                        margin: 5px 0;
                        padding: 8px;
                        font-size: 8px;
                    }
                }
            }
        }
    }

    .pd-sm-0 {
        padding: 0;
    }

    .animation-sec {
        .animated-icon {
            display: none;
        }
    }

    .bakery-card {
        &.theme-card {
            .offer-slider {
                img {
                    height: 90px;
                }
            }
        }

        .offer-slider {
            .media {
                .lable {
                    padding: 6px 5px;
                    font-size: 12px;
                }
            }
        }
    }

    .img-category {
        h4 {
            font-size: 14px;
        }

        .img-sec {
            width: 100%;
        }
    }

    .dark-box {
        .product-box {
            .product-info {
                .add-btn {
                    .btn {
                        padding: 4px 10px;
                    }
                }
            }
        }
    }

    .category-bg {
        &.onerow-cat {
            .order-section {
                div {
                    &:nth-child(2) {
                        order: 3;
                        margin-bottom: 15px;
                        margin-top: 15px;
                    }
                }
            }
        }

        .contain-block {
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }

    .button-dark {
        &.category-button {
            .btn {
                padding: 10px 6px;

                img {
                    margin-right: 8px;
                }
            }
        }
    }

    .absolute-banner {
        &.banner-padding {
            &.banner-style-2 {
                padding-top: 15px;

                .partition2 {
                    >div {
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }

    .banner-style-2 {
        &.absolute-banner {
            .absolute-bg {
                .collection-banner {
                    &:hover {
                        .absolute-img {
                            img {
                                top: 30px;
                            }
                        }
                    }
                }
            }
        }
    }

    .subscribe-form {
        display: block;
        text-align: center;

        .form-group {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .form-control {
            padding: 13px;
        }
    }

    .product-left-title {
        .slick-custom-arrow {
            li {
                width: 28px;
                height: 28px;

                &.left-arrow {
                    margin-right: 6px;
                }
            }

            i {
                font-size: 20px;
            }
        }
    }

    .cycle-box-row {
        margin-bottom: -14px;

        >div {
            margin-bottom: 14px;
        }
    }

    .vector-category {
        margin-top: 0;

        .category-slide {

            .slick-prev,
            .slick-next {
                display: none !important;
            }
        }
    }

    .banner-furniture {
        padding-left: 0;
        padding-right: 0;
    }

    .j-box {
        &.style-box {
            .product-box {
                padding: 5px;
            }
        }
    }

    .product-vertical {
        .full-banner {
            padding-top: 45px !important;
            padding-bottom: 45px !important;
        }
    }

    .cookie-bar {
        display: block;
        text-align: center;
        bottom: -130px;

        p {
            margin-bottom: 8px;
        }

        &.show {
            bottom: 0;
        }
    }

    .recently-purchase {
        left: 10px;

        .media-body {
            padding: 0 12px 0 12px;
        }
    }

    .blackfriday-modal {
        .modal-body {
            .modal-bg {
                padding: 30px 20px;
            }
        }
    }

    .sale-box {
        left: -9px;

        .heading-right {
            h3 {
                transform: translate(50%, -50%) rotate(-90deg) scale(0.6);
            }
        }
    }

    .deal-category {
        .deal-content {
            h2 {
                font-size: 18px;
            }
        }
    }

    .gradient-category {
        .gradient-border {
            height: 220px;
        }
    }

    .subscribe-box {
        display: none !important;
    }

    .newyear-modal {
        .btn-close {
            right: 10px;
            font-size: 13px;
            top: 13px;
        }

        .modal-body {
            .modal-bg {
                padding: 25px;

                .content {
                    margin-top: 0;

                    h1 {
                        max-width: 100%;
                    }
                }
            }
        }
    }

    .shop-sidebar-demo {
        .shop-main {
            padding: 0 15px;
            .slick-sm-margin {
                .slick-list {
                    margin-left: -8px;
                    margin-right: -8px;
                }
                .slick-slide {
                    >div {
                        margin-left: 8px;
                        margin-right: 8px;
                    }
                }
            }
        }
    }

    .product-style-5 {
        &.product-box {
            padding: 10px;
            h6 {
                margin-bottom: 0;
            }
            h4 {
                font-size: 14px;
            }
            .addtocart_btn {
                .add_cart {
                    width: 26px;
                    height: 26px;
                    font-size: 14px;
                    border-radius: 3px;
                    right: 10px;
                    bottom: 10px;
                }
            }
        }
    }

    .sidebar-shop-footer {
        .container-fluid {
            padding: 0 15px;
        }
    }

    // inner pages //
    .template-password {
        #container {
            #login {
                margin-bottom: 0;
            }
        }
    }

    .error-section {
        padding: 100px 0;

        h1 {
            font-size: 100px;
        }

        h2 {
            margin: 20px 0;
            font-size: 18px;
        }
    }

    .about-page {
        .about-head {
            display: inline-block;
            width: 100%;
            text-align: center;

            .theme-breadcrumb {
                .breadcrumb {
                    justify-content: center;
                    padding-top: 10px;
                    padding-bottom: 0;
                }
            }
        }
    }

    .blog-detail-page {
        .blog-detail {
            .post-social {
                li {
                    display: block;

                    &+li {
                        padding-left: 0;
                        margin-left: 0;
                        border-left: none;
                    }
                }
            }
        }
    }

    .collection {
        .collection-block {
            .collection-content {
                p {
                    margin-bottom: 10px;
                }

                h4,
                h3 {
                    margin-bottom: 5px;
                }
            }
        }
    }

    .collection-product-wrapper {
        .product-wrapper-grid {
            .product-box {
                margin-top: 30px;
            }
        }

        .product-top-filter {
            .product-filter-content {
                .search-count {
                    padding: 10px 20px 10px 20px;

                    h5 {
                        font-size: 14px;
                        line-height: 20px;
                    }
                }

                .product-page-per-view,
                .product-page-filter {
                    width: 100%;

                    select {
                        padding: 10px 20px 10px 20px;
                    }

                    &:before {
                        top: 11px;
                    }
                }

                .product-page-per-view {
                    border-right: 1px solid $round-border !important;
                    border-bottom: 1px solid $round-border;
                }
            }

            .popup-filter {
                .search-count {
                    padding-top: 10px;
                    padding-bottom: 10px;
                }

                .product-page-per-view {
                    border-bottom: 1px solid $round-border;
                    width: 100%;

                    select {
                        border-right: none;
                        padding-top: 10px;
                        padding-bottom: 10px;
                    }

                    &:before {
                        top: 14px;
                    }
                }

                .product-page-filter {
                    width: 100%;
                    border-top: none;

                    select {
                        padding-top: 10px;
                        padding-bottom: 10px;
                    }

                    &:before {
                        left: unset;
                        right: 35px !important;
                        top: 14px;
                    }
                }
            }
        }
    }

    .cart-section {
        .cart-buttons {
            >div {
                &:last-child {
                    padding-right: 15px;
                }
            }

            .btn-solid {
                padding: 7px 8px;
                white-space: nowrap;
            }
        }
    }

    .wishlist-section {
        .btn-solid {
            padding: 7px 8px;
        }
    }

    .collection-wrapper {
        .order-up {
            order: -1;
        }
    }

    .checkout-page {
        .checkout-form {
            .checkout-details {
                padding: 15px;
            }
        }
    }

    .product-box {
        .img-wrapper {
            .lable-block {
                .lable3 {
                    font-size: 12px;
                    padding: 13px 8px;
                }
            }
        }

        .img-block {
            .lable-wrapper {

                .lable1,
                .lable2 {
                    padding: 6px 9px 8px 15px;
                    font-size: 12px;
                }
            }
        }
    }

    .tab-product {
        .tab-content {
            &.nav-material {
                p {
                    line-height: 1.5;
                }
            }
        }

        .nav-material {
            &.nav-tabs {
                width: 100%;
                overflow-x: auto;
                overflow-y: visible;
                flex-wrap: unset;
                justify-content: unset;

                .nav-item {
                    .nav-link {
                        white-space: nowrap;
                    }
                }
            }
        }
    }

    .bg-style {
        i {
            padding: 5px !important;
            margin: 0 1px;
            font-size: 12px !important;
        }
    }

    .slider-right-nav {
        .slick-slide {
            &:first-child {
                >div {
                    margin-top: 15px;
                }
            }
        }
    }

    .lookbook {
        .lookbook-block {
            .lookbook-dot {
                .dot-showbox {
                    width: 90px;
                    top: -70px;
                    left: 26px;

                    .dot-info {
                        h5 {
                            line-height: 16px;
                        }
                    }
                }
            }
        }
    }

    .layout7-product {
        .product-box {
            .details-product {
                padding: 5px;
            }

            &:hover {
                .details-product {
                    animation: none;
                }
            }
        }
    }

    .padding-cls {
        padding-left: 15px;
        padding-right: 15px;
    }

    .success-text {
        i {
            font-size: 40px;
        }

        p {
            font-size: 16px;
            margin-bottom: 10px;
            line-height: 1.2;
        }

        h2 {
            margin-bottom: 10px;
        }
    }

    .order-success-sec {
        padding: 10px;
    }

    .delivery-sec {
        margin-top: 0;
        margin-bottom: 0;

        h3 {
            font-size: 16px;
        }
    }

    .product-order {
        h3 {
            font-size: 20px;
        }

        .product-order-detail {
            .order_detail {

                h4,
                h5 {
                    font-size: 14px;
                    line-height: 1.2;
                }
            }
        }

        .total-sec {
            ul {
                li {
                    font-size: 16px;
                }
            }
        }

        .final-total {
            h3 {
                font-size: 16px;
            }
        }
    }

    .beauty-about {
        .service {
            .service-block1 {
                margin-bottom: 20px;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .main-menu {
        .menu-left {
            .navbar {
                i {
                    font-size: 24px;
                }
            }
        }
    }

    .typography_section {
        .typography-box {
            .typo-content {
                &.typo-buttons {
                    a {
                        &:last-child {
                            margin-top: 0;
                        }
                    }

                    .btn-solid {
                        &.btn-sm {
                            padding: 3px 12px;
                        }
                    }
                }
            }
        }
    }

    .bundle {
        .bundle_img {
            .img-box {
                img {
                    max-width: 70px;
                }
            }
        }

        .bundle_detail {
            .theme_checkbox {
                padding: 0;
            }
        }
    }

    .vendor-cover {
        .bg-size {
            height: 250px;
        }
    }

    .vendor-profile {
        margin-top: -50px;
    }

    .start-selling {
        form {
            .row {
                >div {
                    &:nth-child(2) {
                        margin-top: 15px;
                    }
                }
            }
        }
    }

    .become-vendor {
        .step-bg {
            .step-box {
                .steps {
                    margin: 0 auto 15px auto;
                }
            }
        }
    }

    .bg-title {
        .theme-tab {
            .bg-title-part {
                display: block;
                text-align: center;

                .tab-title {
                    margin-left: unset;
                    text-align: center;

                    li {
                        padding-left: 5px;
                        padding-right: 5px;
                    }

                    &.w-bg {
                        margin-top: 15px;
                    }
                }
            }
        }
    }

    .dashboard-right {
        .dashboard {
            padding: 20px;
        }
    }

    .portfolio-section {
        &.metro-section {
            .product-box {
                .product-detail {
                    &.default-view {
                        bottom: 0;
                        width: 100%;
                    }
                }
            }
        }
    }

    .video-product {
        iframe {
            height: 40vh;
            margin-top: 60px;
        }
    }

    .tracking-page {
        .tracking-detail {
            margin-top: 10px;

            ul {
                li {
                    font-size: 14px;

                    .left {
                        min-width: 140px;
                        width: 200px;
                    }
                }
            }
        }

        .wrapper {
            margin: 30px 0 5px;
        }

        .table {
            td {
                min-width: 170px;
            }
        }
    }

    .sticky-bottom-cart {
        .selection-section {
            .form-control {
                text-transform: capitalize;
                font-size: 13px;
            }

            .form-group {
                width: 80px;
            }
        }

        .add-btn {
            .btn {
                padding: 5px 8px;
                font-size: 13px;
                text-transform: capitalize;
            }
        }
    }

    .faq-tab {
        .nav-tabs {
            .nav-item {
                margin-bottom: 1px;
            }
        }
    }

    .invoice-wrapper {
        h2 {
            font-size: 20px;
        }

        .btn-solid,
        .btn-outline {
            padding: 6px 13px;
            font-size: 12px;
        }

        .authorise-sign {
            width: 30%;
        }

        .invoice-body {
            .table {
                tbody {

                    td,
                    th {
                        &:nth-child(2) {
                            min-width: 240px;
                        }
                    }
                }
            }
        }
    }

    .theme-invoice-2 {
        .invoice-body {
            .date-detail {
                margin-bottom: 14px;
                padding-bottom: 14px;

                li {
                    width: 100%;
                }
            }
        }
    }

    .theme-invoice-4 {
        .invoice-header {
            .background-invoice {
                left: 40px;
            }
        }
    }

    .element-page {
        margin-bottom: 60px;
    }

    // loader
    .loader_skeleton {
        header {
            &.header-gym {
                top: 0;
                background-color: black;
            }
        }

        .header-dropdown {
            display: none;
        }
    }

    .layout-8 {
        .loader_skeleton {
            padding: 0 0;
        }
    }
}

@media (max-width: 575px) {
    .breadcrumb-section {
        .page-title {
            text-align: center;
            margin: 0 auto;
            vertical-align: middle;
            display: unset;

            h2 {
                margin-top: -2px;
            }
        }

        .breadcrumb {
            justify-content: center;
            padding: 0;
            line-height: 1;
            margin-top: 10px;
        }
    }
}

@media (max-width: 480px) {
    .title1 {
        .title-inner1 {
            margin-bottom: 20px;
        }

        &.title5 {
            hr[role="tournament6"] {
                margin: 12px auto 20px auto;
            }
        }
    }

    .title2 {
        .title-inner2 {
            margin-bottom: 20px;
        }
    }

    .title3 {
        .line {
            margin-bottom: 20px;
        }
    }

    .title4 {
        .line {
            margin-bottom: 20px;
        }
    }

    .title-borderless {
        margin-bottom: 20px;
    }

    .setting-box {
        .setting-title {
            img {
                width: 60%;
            }
            h3 {
                line-height: 24px;
                font-size: 18px;
            }
        }
    }

    .demo-section {
        .demo-effects {
            margin-bottom: 20px;
            >div {
                .layout-container {
                    height: 80px;
                }
                .demo-text {
                    h4 {
                        span {
                            position: absolute;
                            top: 0;
                            right: 7px;
                            padding: 0;
                            font-size: 11px;
                            border-radius: 2px;
                            margin-left: 0;
                            width: 36px;
                            height: 18px;
                            line-height: 18px;
                        }
                    }
                }
            }
        }
    }

    .service_slide {
        .home-slider {
            .slider-contain {
                height: 55vh;
            }
        }
    }

    .advertise-section {
        .full-banner {
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }

    header {
        &.left-header {
            .main-menu {
                .menu-left {
                    .mobile-logo {
                        top: 22px;
                    }
                }
            }
        }
    }

    .gym-banner {
        .collection-banner {
            .contain-banner {
                >div {
                    background-color: rgba($black, 0.5);
                    padding: 10px;
                }

                h2 {
                    font-size: 20px;
                    margin-top: 4px;
                }

                h4 {
                    font-size: 14px;
                }
            }
        }
    }

    .subscribe-form {
        &.classic-form {
            .form-control {
                margin-left: 0;
            }
        }
    }

    .theme-tab {
        .tab-title {
            li {
                padding-left: 5px;
                padding-right: 5px;
            }
        }

        .tab-title2 {
            margin-bottom: 30px;
            font-size: 18px;
            font-weight: 400;

            li {
                padding-left: 15px;
                padding-right: 15px;
            }

            &:after {
                display: none;
            }
        }
    }

    .blog-details {
        padding-left: 0;
        padding-right: 0;
    }

    .full-banner {
        padding-top: 40px;
        padding-bottom: 40px;

        &.parallax-layout {
            padding-top: 40px;
            padding-bottom: 40px;
        }

        .banner-contain {
            h2 {
                font-size: 60px;
            }

            h4 {
                font-size: 18px;
            }
        }

        .santa-img {
            bottom: -20px;
            left: 10px;

            img {
                width: 76px;
            }
        }
    }

    .pet-parallax {
        .full-banner {
            padding-top: 40px;
            padding-bottom: 40px;
        }
    }

    .collection-banner {
        .contain-banner {
            padding-left: 30px;
            padding-right: 30px;

            h2 {
                font-size: 24px;
            }
        }
    }

    .home-slider {
        .home {
            // height: 45vh;
            //height: 17vh; // edited
            max-width: 100% !important;
            max-height: 17vh !important; //added

            img {
                width: 100%; /* Ensure the image takes up the full width of its container */
                height: auto; /* Automatically adjust the height to maintain the aspect ratio */
                max-width: 100% !important; /* Limit the maximum width of the image */
                height: 17vh !important; /* Limit the maximum height of the image */
                display: block !important; /* Ensure the image behaves as a block element */
                margin: 0 auto; /* Center the image horizontally */
            }
        }

        .slider-details {
            width: 150px !important;
            height: 150px !important;

            h1 {
                font-size: 20px;
            }

            h2 {
                font-size: 16px;
            }

            h3 {
                font-size: 14px;
            }
        }

        .slider-contain {
            // height: 45vh;
            height: 17vh; // edited
            transform: translate(-40%, -105%); // edited
            justify-content: space-evenly; // edited

            h1 {
                font-size: 28px;
            }

            h4 {
                letter-spacing: 0.1em;
            }

            .btn-solid,
            .btn-outline {
                margin-top: 5px;
            }
        }

        &:hover {

            .slick-prev,
            .slick-next {
                transform: scale(1.2);
            }

            .slick-next {
                right: 60px;
            }

            .slick-prev {
                left: 60px;
            }
        }
    }

    .height-85 {
        .home-slider {
            .home {
                height: 55vh;
            }

            .slider-contain {
                height: 55vh;
            }
        }
    }

    .white-layout {
        .footer-theme2 {
            .footer-block {
                .footer-container {
                    max-width: 448px;
                }
            }
        }
    }

    .footer-theme2 {
        &.section-light {
            .footer-block {
                .subscribe-white {
                    padding: 30px 30px;

                    .form-control {
                        letter-spacing: 3px;
                    }
                }
            }
        }
    }

    .theme-modal {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    .modal-bg {
                        padding: 35px;

                        .offer-content {
                            img {
                                margin-bottom: 10px;
                            }

                            h2 {
                                margin-bottom: 15px;
                            }
                        }

                        .age-content {
                            form {
                                .form-control {
                                    height: 40px;
                                    padding: 0;
                                }

                                .btn-solid,
                                .btn-outline {
                                    padding: 8px 15px;
                                }
                            }
                        }
                    }
                }
            }
        }

        &.cart-modal {
            .modal-dialog {
                .modal-content {
                    .modal-body {
                        .modal-bg {
                            &.addtocart {
                                .media {
                                    display: block;
                                    text-align: center;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .theme-tab {
        .tab-content {
            .product-tab {
                .tab-box {
                    .product-box2 {
                        .media {
                            .media-body {
                                h4 {
                                    font-size: 15px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .tab-bg,
    .bg-block {
        padding: 35px 25px 30px 25px;
    }

    .setting-box {
        width: 280px;
    }

    .setting-sidebar {
        &.open-icon {
            right: 278px;
        }
    }

    .setting-box {
        .setting-contant {
            .setting_buttons {
                display: flex;

                li {
                    width: 40% !important;
                }
            }
        }
    }

    .upper-canvas-section {
        &.height-100 {
            .home-slider {
                .slider-contain {
                    &.left-canvas-section {
                        padding-top: 80px;
                    }
                }
            }
        }
    }

    .lower-canvas-section {
        .left-canvas-section {
            padding: 20px 0 20px;
        }
    }

    .banner-style-1 {
        .full-box {
            .theme-card {
                .offer-slider {
                    .product-box2 {
                        .media {
                            img.h-cls {
                                height: 160px;
                                max-width: unset;
                            }
                        }
                    }
                }
            }
        }
    }

    .timer-section {
        .timer {
            padding-left: 30px;

            span {
                width: 55px;
            }
        }
    }

    .product-vertical {
        .full-box {
            .theme-card {
                .offer-slider {
                    .product-box2 {
                        .media {
                            img {
                                height: auto;
                            }
                        }
                    }
                }
            }
        }
    }

    .gradient-category {
        .gradient-border {
            height: 180px;
        }
    }

    // inner pages //
    .select_input {
        select {
            padding: 12px 15px;
            margin-bottom: 18px;
        }
    }

    .about-page {
        p {
            line-height: 1.5;
            margin-bottom: 0;
        }
    }

    .testimonial {
        .testimonial-slider {
            .media {
                display: inline-block;

                img {
                    margin: 0 auto;
                    height: 100px;
                    width: 100px;
                }

                .media-body {
                    padding: 0;
                    text-align: center;
                }
            }
        }
    }

    .blog-detail-page {
        .blog-contact {
            h2 {
                font-size: 30px;
                margin-bottom: 25px;
            }

            .theme-form {

                input,
                textarea {
                    padding: 12px 20px;
                    margin-bottom: 20px;
                }
            }
        }

        .blog-detail {
            h3 {
                font-size: 20px;
                margin-bottom: 15px;
            }

            img {
                margin-bottom: 25px;
            }
        }

        .blog-advance {
            ul {
                line-height: 1.5;
            }

            img {
                margin-bottom: 20px;
                margin-top: 10px;
            }

            p {
                line-height: 1.5;
            }
        }

        .comment-section {
            li {
                padding-top: 30px;
                padding-bottom: 30px;

                p {
                    line-height: 1.5;
                }

                h6 {
                    margin-top: 10px;
                    margin-bottom: 10px;

                    span {
                        display: inherit;
                        margin-left: 0;
                    }
                }
            }

            .media {
                display: inline-block;
                text-align: center;
            }
        }
    }

    .faq-section {
        .theme-accordion {
            .card {
                .card-body {
                    p {
                        line-height: 1.5 !important;
                    }
                }

                .card-header {
                    button {
                        line-height: 1.3;
                        padding: 12px 35px 12px 12px !important;
                    }
                }
            }
        }

        .accordion {
            &.theme-accordion {
                .card {
                    .card-header {
                        button {
                            &:before {
                                top: 20px;
                            }
                        }
                    }
                }
            }
        }
    }

    .pwd-page {
        padding: 70px 0;

        h2 {
            font-size: 25px;
            margin-bottom: 15px;
        }
    }

    .login-page {
        .authentication-right {
            h6 {
                margin-bottom: 10px;
            }
        }

        .theme-card {
            padding: 25px;
        }
    }

    .register-page {
        .theme-card {
            padding: 18px;

            .theme-form {
                input {
                    margin-bottom: 20px;
                    padding: 12px 15px;
                }
            }
        }
    }

    .search-block {
        .btn-solid {
            padding: 10px 15px;
        }
    }

    .contact-page {
        .map {
            iframe {
                height: 275px;
            }
        }

        .theme-form {
            input {
                padding: 12px 15px;
                margin-bottom: 18px;
            }
        }
    }

    .blog-page {
        .blog-media {
            .blog-right {
                h6 {
                    margin-bottom: 0;
                }

                p {
                    line-height: 1.3;
                }

                h4 {
                    margin-bottom: 5px;
                }

                ul {
                    margin-bottom: 5px;

                    li {
                        display: block;

                        &+li {
                            padding-left: 0;
                            margin-left: 0;
                            border-left: none;
                        }
                    }
                }
            }
        }
    }

    .account-sidebar {
        width: 45%;
    }

    .success-text {
        h2 {
            font-size: 20px;
        }

        p {
            font-size: 14px;
        }
    }

    .typography_section {
        .typography-box {
            .typo-content {
                &.typo-buttons {
                    a {
                        margin: 5px 0;
                    }
                }
            }
        }
    }

    .dashboard-section {
        .dashboard-box {
            .dashboard-detail {
                ul {
                    li {
                        .details {
                            display: block;

                            .left {
                                width: auto;

                                h6 {
                                    font-weight: 600;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .theme-card {
        .offer-slider {
            img {
                max-width: 130px;
                height: auto;
            }
        }
    }

    .tab-product {
        .tab-content.nav-material {
            .single-product-tables {
                table {
                    width: 100%;
                }
            }
        }
    }

    .single-product-tables {
        display: block;
        margin-top: 0;

        table {
            tr {
                td {
                    &:first-child {
                        width: 140px;
                    }
                }
            }
        }
    }

    .mordern-box-margin {
        margin-bottom: -30px;
    }

    .mordern-box {
        margin-top: 24px;
        padding: 15px 10px;
        margin-bottom: 30px;

        .absolute-img {
            img {
                margin-top: -50px;
            }
        }

        .mordern-content {
            margin-top: 10px;

            h6 {
                font-size: 14px;
                line-height: 1.3;
            }

            h4 {
                font-size: 13px;
            }
        }
    }

    .cart_counter {
        display: block;

        .cart_checkout {
            margin-left: 0;
        }
    }

    .theme-invoice-4 {
        .invoice-header {
            .background-invoice {
                left: 110px;
            }
        }
    }
}

@media (max-width: 420px) {
    h2 {
        font-size: 22px;
    }

    .header-style-1 {
        .navbar {
            .nav-link {
                padding-right: 12px;
            }
        }
    }

    .product-pagination {
        margin: 30px 0;
    }

    .margin-res {
        margin-left: -10px;
        margin-right: -10px;

        >div {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .portfolio-section {
        &.metro-section {
            .product-box {
                .product-detail {
                    width: 95%;
                    bottom: 5px;

                    h6,
                    h4 {
                        font-size: 12px;
                    }
                }

                .cart-wrap {

                    button,
                    a {
                        i {
                            font-size: 10px;
                            padding: 5px;
                        }
                    }
                }
            }
        }
    }

    .collection-product-wrapper {
        .product-wrapper-grid {
            .product-box {
                margin-top: 20px;
            }
        }
    }

    .subscribe {
        h4 {
            display: block;
        }
    }

    footer {
        p {
            line-height: 25px;
        }

        &.footer-black {
            .subscribe-form {
                .form-control {
                    height: 35px;
                    width: 220px;
                }
            }
        }
    }

    .blog-details {
        p {
            font-size: 14px;
            line-height: 1.3;
        }
    }

    .no-slider {
        margin-left: -10px !important;
        margin-right: -10px !important;

        .product-box {
            margin: 0 10px 20px !important;
            max-width: calc(50% - 20px);

            &:nth-last-child(-n + 2) {
                margin: 0 10px 0 !important;
            }
        }

        &.five-product {
            .product-box {
                margin: 0 10px 20px !important;
                max-width: calc(50% - 20px);

                &:nth-last-child(-n + 2) {
                    margin: 0 10px 0 !important;
                }
            }
        }
    }

    .product-m {
        .slick-list {
            margin-left: -10px;
            margin-right: -10px;
        }

        .slick-slide {
            >div {
                margin: 0 10px;
            }
        }
    }

    .product-box,
    .product-wrap {
        .cart-detail {
            i {
                padding-top: 5px;
                padding-bottom: 5px;
                font-size: 14px;
            }
        }

        .cart-info,
        .cart-wrap {
            i {
                padding: 5px;
                font-size: 13px;
            }
        }

        .img-wrapper {
            .lable-block {
                .lable4 {
                    font-size: 10px;
                }
            }

            .cart-box {
                i {
                    font-size: 14px;
                    padding-left: 4px;
                    padding-right: 4px;
                }
            }
        }

        .product-detail,
        .product-info {
            margin-top: 5px;

            h6 {
                font-size: 14px;
            }

            h4 {
                font-size: 15px;
            }

            .color-variant {
                padding-top: 3px;

                li {
                    height: 14px;
                    width: 14px;
                    margin-right: 3px;
                }
            }
        }

        .cart-info,
        .cart-wrap {
            &.cart-wrap {
                i {
                    padding: 5px;
                    font-size: 13px;
                }
            }
        }
    }

    .absolute_banner {
        .collection-banner {
            .absolute-contain {
                padding: 10px;
                bottom: 10px;
            }
        }
    }

    .absolute-banner {
        &.search-section {
            .absolute-bg {
                .row {
                    >div {
                        &.search-col {
                            .search-btn {
                                width: 100%;
                            }
                        }
                    }
                }
            }
        }
    }

    .absolute-product {
        .product-box {
            .product-detail {
                .cart-bottom {
                    i {
                        padding-right: 4px;
                        padding-left: 4px;
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .layout7-product {
        .no-slider {
            .product-box {
                max-width: calc(100% - 20px);
                flex: 0 0 100%;

                &:nth-last-child(-n + 2) {
                    margin: 0 10px 30px !important;
                }

                &:last-child {
                    margin: 0 10px 0 !important;
                }
            }
        }
    }

    .addtocart_count {
        .product-box {
            .cart-info {
                a {
                    i {
                        margin: 4px 0;
                        padding: 5px;
                        font-size: 14px;
                    }
                }
            }

            .add-button {
                font-size: 14px;
            }
        }
    }

    .timer {
        span {
            .padding-l {
                padding-left: 5px;
            }
        }
    }

    .about-text {
        p {
            line-height: 22px;
        }
    }

    .service_slide {
        .service-home {
            .service-block1 {
                svg {
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }

    .footer-theme {
        .sub-title {
            h4 {
                margin-bottom: 5px;
            }

            .contact-list {
                li {
                    line-height: 25px;
                }
            }
        }
    }

    .footer-theme2 {
        p {
            line-height: 25px;
        }
    }

    .breadcrumb-section {
        padding: 15px 0;
    }

    .footer-light {
        .subscribe-form {
            .form-control {
                width: 280px;
            }
        }
    }

    .white-layout {
        .footer-theme2 {
            .footer-block {
                .footer-container {
                    max-width: 388px;
                }

                .subscribe-white {

                    .btn-solid,
                    .btn-outline {
                        padding: 13px 8px;
                    }
                }
            }
        }
    }

    .dark-layout {
        .footer-theme2 {
            .subscribe-wrapper {
                .subscribe-block {

                    .btn-solid,
                    .btn-outline {
                        padding: 13px 8px;
                    }
                }
            }
        }
    }

    .footer-theme2 {
        .subscribe-block {
            .form-control {
                letter-spacing: 3px;
            }
        }
    }

    .subscribe-form {
        display: inline-block;

        .form-group {
            margin-bottom: 10px;
        }
    }

    .category-border {
        div {
            .category-banner {
                .category-box {
                    h2 {
                        font-size: 25px;
                    }
                }
            }
        }
    }

    .tools-grey {
        .product-box {
            .product-info {
                padding: 8px;

                h4 {
                    font-size: 13px;
                }
            }
        }
    }

    .j-box {
        .product-box {
            .product-detail {
                padding-left: 4px;
                padding-bottom: 8px;
                padding-right: 4px;
                padding-top: 4px;
            }
        }
    }

    .full-banner {
        .banner-contain {
            h2 {
                font-size: 55px;
            }

            h3 {
                font-size: 36px;
            }
        }
    }

    .home-slider {
        .slider-contain {
            h1 {
                font-size: 25px;
                margin-top: 10px;
            }

            h4 {
                letter-spacing: 0.01em;
            }

            .btn-solid,
            .btn-outline {
                margin-top: 10px;
                // padding: 5px 18px; // modified
                padding: 2px 4px;
            }
        }

        .slider-details {
            width: 120px !important;
            height: 120px !important;
            top: 24%;

            h1 {
                font-size: 18px;
            }

            h2 {
                font-size: 14px;
            }

            h3 {
                font-size: 12px;
            }

            .btn-white {
                padding: 0px 2px;
                margin-top: 0;
                font-size: 10px;
            }
        }

        &:hover {

            .slick-prev,
            .slick-next {
                transform: scale(1.5);
            }

            .slick-next {
                right: 50px;
            }

            .slick-prev {
                left: 50px;
            }
        }
    }

    .theme-card {
        &.center-align {
            display: unset;
            height: unset;
            align-items: unset;
        }

        &.card-border {
            .offer-slider {
                img {
                    padding: 10px 10px 10px 15px;
                }
            }
        }
    }

    .full-box {
        .center-slider {
            .offer-slider {
                .product-box {
                    display: flex;
                    border-bottom: 1px solid $border-grey;
                    border-top: 1px solid $border-grey;
                    padding: 15px 0;

                    .img-wrapper {
                        img {
                            height: 120px;
                            padding: 0 15px 0 0;
                        }
                    }

                    .product-info {
                        text-align: left;

                        h6 {
                            padding-top: 5px;
                        }

                        .color-variant {
                            padding-top: 2px;

                            li {
                                height: 15px;
                                width: 15px;
                                padding-right: 3px;
                            }
                        }
                    }

                    .product-detail {
                        padding-left: 0;

                        h4 {
                            font-size: 20px;
                        }

                        .color-variant {
                            padding-top: 0;
                        }
                    }

                    &:hover {
                        .product-info {
                            .btn-outline {
                                display: none;
                            }

                            .color-variant {
                                opacity: 1;
                            }
                        }
                    }
                }
            }
        }

        .theme-card {
            .offer-slider {
                .sec-1 {
                    display: inline;

                    .product-box2 {
                        border-bottom: 1px solid $border-grey;
                        padding-bottom: 15px;

                        &:last-child {
                            border-bottom: none;
                        }

                        +.product-box2 {
                            border-left: none;
                        }
                    }
                }
            }
        }
    }

    .center-slider {
        .offer-slider {
            .product-box {
                .product-detail {
                    text-align: left !important;

                    .rating {
                        i {
                            padding-right: 3px;
                        }
                    }

                    h4 {
                        padding-bottom: 0.5rem;
                    }

                    .color-variant {
                        li {
                            height: 18px;
                            width: 18px;
                            padding-right: 3px;
                        }
                    }
                }

                .img-wrapper {
                    .cart-box {
                        display: none;
                    }
                }
            }
        }
    }

    .product-box {
        .img-wrapper {
            .lable-block {
                .lable3 {
                    font-size: 10px;
                    padding: 10px 5px;
                }
            }

            .cart-box {
                padding: 4px 8px;
                bottom: 10px;
            }
        }

        .img-block {
            .lable-wrapper {
                top: 20px;

                .lable1,
                .lable2 {
                    padding: 5px 5px 5px 8px;
                    font-size: 12px;
                }
            }
        }

        .cart-info {
            bottom: 10px;
        }
    }

    .team {
        h6 {
            line-height: 15px;
        }
    }

    .theme-modal {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    .modal-bg {
                        padding: 30px;

                        .offer-content {
                            h2 {
                                font-size: 30px;
                            }
                        }
                    }
                }
            }
        }
    }

    .login-page {
        .authentication-right {
            p {
                line-height: 1.5;
            }
        }

        .theme-card {
            .theme-form {
                input {
                    padding: 15px 20px;
                    margin-bottom: 25px;
                }
            }
        }
    }

    .tab-product {
        .nav-material {
            &.nav-tabs {
                .nav-item {
                    .nav-link {
                        padding: 10px 20px;
                        padding-top: 0;
                    }
                }
            }
        }
    }

    .vertical-tab {

        &.tab-product,
        .product-full-tab {
            .nav-material {
                &.nav-tabs {
                    .nav-item {
                        .nav-link {
                            padding: 10px 20px;
                        }
                    }
                }
            }
        }
    }

    .product-right {
        .product-buttons {

            .btn-solid,
            .btn-outline {
                padding: 7px 8px;
            }
        }

        &.product-form-box {
            .timer {
                padding-left: 29px;

                span {
                    width: 45px;
                }
            }
        }

        .timer {
            padding-left: 35px;

            span {
                width: 45px;
            }
        }

        .product-icon {
            .product-social {
                margin-top: 0;

                li {
                    padding-right: 5px;

                    a {
                        i {
                            font-size: 14px;
                        }
                    }
                }
            }

            .wishlist-btn {
                i {
                    font-size: 14px;
                    padding-left: 10px;
                    margin-left: 5px;
                }

                span {
                    font-size: 14px;
                }
            }
        }
    }

    .tab-product {
        .theme-form {

            input,
            textarea {
                font-size: 12px;
                padding: 8px 15px;
            }
        }
    }

    .order-box {
        .sub-total {
            .shipping {
                width: unset;
                float: unset;
                display: block;

                .shopping-option {
                    label {
                        margin-bottom: 0;
                    }

                    &:last-child {
                        padding-left: 0;
                    }
                }
            }
        }
    }

    .checkout-page {
        .checkout-form {
            .form-group {
                margin-bottom: 15px;
            }

            input {

                &[type="text"],
                &[type="email"],
                &[type="password"],
                &[type="tel"],
                &[type="number"],
                &[type="url"] {
                    height: 40px;
                }
            }

            select,
            textarea {
                height: 40px;
            }
        }
    }

    .grid-products {
        margin-left: -10px;
        margin-right: -10px;

        .product-box {
            padding-left: 10px;
            padding-right: 10px;
        }
    }

    .game-product {
        .product-box {
            .add-button {
                font-size: 14px;
                bottom: 6px;
            }
        }
    }

    .cycle-box {
        .cart-detail {
            li {
                padding: 0 8px;
            }
        }
    }

    .banner-style-1 {
        .theme-card {
            &.center-align {
                display: block;

                .offer-slider {
                    .product-box2 {
                        .media {
                            .media-body {
                                margin: 10px 0;
                            }
                        }
                    }
                }
            }
        }

        .full-box {
            .theme-card {
                .offer-slider {
                    .product-box2 {
                        .media {
                            img.h-cls {
                                height: 130px;
                            }
                        }
                    }
                }
            }
        }
    }

    .sticky-bottom-cart {
        .product-image {
            img {
                width: 50px;
                height: 60px;
            }
        }

        .selection-section {
            .form-group {
                width: 60px;
            }
        }

        .add-btn {
            .btn {
                padding: 5px 4px;
                font-size: 12px;
            }
        }
    }

    .tracking-page {
        .tracking-detail {
            ul {
                li {
                    .left {
                        width: 170px;
                    }
                }
            }
        }
    }

    .invoice-wrapper {
        .authorise-sign {
            width: 20%;
        }

        .btn-solid,
        .btn-outline {
            padding: 4px 8px;
        }
    }

    .deal-category {
        .deal-content {
            width: calc(100% - 15px);
            height: calc(100% - 15px);

            h2 {
                font-size: 15px;
                margin-bottom: 3px;
            }
        }
    }

    .gradient-category {
        .gradient-border {
            height: 140px;
        }
    }
}

@media (max-width: 360px) {

    .btn-solid,
    .btn-outline {
        padding: 10px 15px;
    }

    h2 {
        font-size: 25px;
    }

    .shop-sidebar-demo {
        .shop-main {
            .small-slider {
                .home {
                    .slider-contain {
                        .btn {
                            padding: 5px 5px;
                            font-size: 12px;
                        }
                    }
                }
            }
        }
    }

    .advertise-section {
        .full-banner {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }

    .category-button {
        .col {
            flex-basis: unset;
        }
    }

    .gym-banner {
        .collection-banner {
            .contain-banner {
                h2 {
                    font-size: 18px;
                }
            }
        }
    }

    .service_slide {
        .home-slider {
            .slider-contain {
                height: 50vh;
            }
        }
    }

    .tools-parallax-product {
        .tools-description {
            .tools-form {
                .search-box {
                    width: 92%;
                }
            }
        }
    }

    footer {
        .btn-solid {
            padding: 7px 8px;
        }
    }

    .footer-theme2 {
        .subscribe-block {
            padding: 0 30px;
        }
    }

    .white-layout {
        .small-section {
            .footer-theme2 {
                .col {
                    padding: 0 15px;
                }
            }
        }

        .footer-theme2 {
            .footer-block {
                .footer-container {
                    max-width: 330px;
                }
            }
        }
    }

    .footer-theme2 {
        &.section-light {
            .footer-block {
                .subscribe-white {
                    padding: 20px 20px;
                }
            }
        }
    }

    .dark-layout {
        .footer-theme2 {
            .subscribe-wrapper {
                .subscribe-block {
                    .form-group {
                        .form-control {
                            border: none;
                        }
                    }
                }
            }
        }
    }

    .footer-end {
        p {
            font-size: 13px;
        }
    }

    .category-border {
        div {
            .category-banner {
                .category-box {
                    h2 {
                        padding: 7px 15px;
                    }
                }
            }
        }
    }

    .full-banner {
        padding-top: 30px;
        padding-bottom: 30px;

        &.parallax-layout {
            padding-top: 30px;
            padding-bottom: 30px;
        }

        .banner-contain {
            h2 {
                font-size: 55px;
            }

            h3 {
                font-size: 30px;
            }
        }
    }

    .pet-parallax {
        .full-banner {
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }

    .home-slider {
        .home {
            // height: 40vh;
            height: 18vh;
        }

        .slider-contain {
            // height: 40vh;
            height: 18vh;

            h1 {
                font-size: 22px;
            }

            h4 {
                font-size: 14px;
            }

            .btn-solid,
            .btn-outline {
                margin-top: 8px;
                // padding: 5px 15px;
                padding: 1px 6px
            }
        }

        .slider-details {
            top: 20%;

            h1 {
                font-size: 16px;
            }

            h2 {
                font-size: 13px;
            }
        }

        &:hover {

            .slick-prev,
            .slick-next {
                transform: scale(1.1);
            }

            .slick-next {
                right: 40px;
            }

            .slick-prev {
                left: 40px;
            }
        }
    }

    .height-85 {
        .home-slider {
            .home {
                height: 50vh;
            }

            .slider-contain {
                height: 50vh;
            }
        }
    }

    .layout-7.home-slider {
        .home {
            // height: 40vh;
            height: 22vh; // edited

            .slider-contain {
                // height: 40vh;
                height: 22vh; // edited
            }
        }

        &.sm-size {
            .home {
                height: 30vh;

                .slider-contain {
                    height: 30vh;
                }
            }
        }
    }

    .theme-tab {
        .tab-content {
            .product-tab {
                .tab-box {
                    .product-box2 {
                        .media {
                            .media-body {
                                a {
                                    h6 {
                                        font-size: 13px;
                                    }
                                }

                                .color-variant {
                                    li {
                                        height: 12px;
                                        width: 12px;
                                    }
                                }
                            }
                        }

                        img {
                            height: 115px;
                        }
                    }
                }
            }
        }
    }

    .blog-detail-page {
        .blog-detail {
            p {
                line-height: 1.5;
            }
        }

        .blog-contact {
            h2 {
                font-size: 20px;
                margin-bottom: 20px;
            }
        }
    }

    .about-page {
        p {
            line-height: 1.2;
        }
    }

    .theme-modal {
        .modal-dialog {
            .modal-content {
                .modal-body {
                    padding: 10px;

                    .modal-bg {
                        padding: 20px;

                        .btn-close {
                            right: 10px;
                        }

                        .offer-content {
                            form {
                                .form-group {
                                    .form-control {
                                        padding-top: 10px;
                                        padding-bottom: 10px;
                                    }
                                }
                            }

                            h2 {
                                font-size: 20px;
                                margin-bottom: 10px;
                            }
                        }
                    }
                }
            }
        }
    }

    .login-page {
        .theme-card {
            padding: 18px;
        }
    }

    .search-block {
        .btn-solid {
            padding: 10px 5px;
        }

        .form-header {
            .input-group {
                input {
                    padding: 10px 12px;
                }
            }
        }
    }

    .contact-page {
        .map {
            iframe {
                height: 200px;
            }
        }
    }

    .collection-product-wrapper {
        .product-pagination {
            .pagination {
                .page-item {
                    a {
                        padding: 15px 13px;
                    }
                }
            }

            .product-search-count-bottom {
                padding-left: 10px;

                h5 {
                    line-height: 15px;
                }
            }
        }

        .product-top-filter {
            .popup-filter {
                .sidebar-popup {
                    width: 80%;
                }
            }
        }
    }

    .cart-section,
    .wishlist-section {
        tbody {
            tr {
                td {
                    min-width: 115px;

                    .mobile-cart-content {
                        .col {
                            margin-left: 5px;
                            margin-right: 5px;
                        }
                    }

                    a {
                        img {
                            height: 80px;
                        }
                    }
                }
            }
        }

        tfoot {
            tr {
                td {
                    padding-right: 27px;

                    h2 {
                        font-size: 21px;
                    }
                }
            }
        }
    }

    .wishlist-section {
        .wishlist-buttons {
            &:last-child {
                a {
                    margin-left: 0;
                }
            }
        }
    }

    .product-buttons {

        .btn-solid,
        .btn-outline {
            padding: 7px 16px;
        }
    }

    .product-description {
        .qty-box {
            .input-group {
                width: 100%;
            }
        }
    }

    .single-product-tables {
        &.detail-section {
            table {
                width: 100%;
            }
        }
    }

    .product-right {
        .timer {
            padding-left: 25px;

            span {
                .padding-l {
                    padding-left: 10px;
                }
            }
        }

        .product-buttons {
            a {
                &:last-child {
                    margin-left: 6px;
                }
            }
        }
    }

    .product-form-box {
        .timer {
            span {
                width: 47px;

                .padding-l {
                    padding-left: 9px;
                }
            }
        }

        .product-buttons {

            .btn-solid,
            .btn-outline {
                padding: 5px 5px;
            }
        }
    }

    .product-form-box,
    .border-product {
        .timer {
            span {
                width: 45px;

                .padding-l {
                    padding-right: 5px;
                }
            }
        }
    }

    .d-none-frm-xs {
        display: none;
    }

    .gradient-category {
        .gradient-border {
            height: 130px;
        }
    }
}

@media (max-width: 320px) {
    .home-slider {
        .slider-details {
            top: 16%;
        }
    }

    .footer-theme {
        .footer-logo {
            margin-bottom: 20px;
        }
    }

    .footer-theme2 {
        .col {
            padding: 10px 5px;
        }
    }

    .white-layout {
        .footer-theme2 {
            .col {
                padding: 0 15px;
            }

            .footer-block {
                .footer-container {
                    max-width: 290px;
                }
            }
        }
    }

    .dark-layout {
        .footer-theme2 {
            .subscribe-wrapper {
                .subscribe-block {
                    .form-group {
                        .form-control {
                            letter-spacing: 1px;
                        }
                    }
                }
            }
        }
    }

    .footer-theme2 {
        &.section-light {
            .footer-block {
                .subscribe-white {
                    .form-control {
                        letter-spacing: 1px;
                    }
                }
            }
        }
    }

    .collection-banner {
        .contain-banner {
            &.banner-3 {
                padding-left: 25px;
                padding-right: 25px;

                h2 {
                    font-size: 15px;
                }
            }

            &.banner-4 {
                padding-left: 35px;
                padding-right: 35px;

                h2 {
                    font-size: 15px;
                }
            }
        }
    }

    .gradient-category {
        .gradient-border {
            height: 120px;
        }
    }
}